import { Separator } from '../separator/separator';
import { Status } from './status';
import { DevMode } from './dev-mode';
import { MenuItemHelp } from './menu-item-help';
import { MenuItemSettings, MenuItemSettingsActionsProps } from './menu-item-settings';
import { SpaceName } from './space-name';
import { Status as StatusEnum } from '@xspecs/single-source-model';
import { Fragment, useCallback, useMemo } from 'react';
import { useStoreContext } from '../../wrappers/store-provider';
import { useIntl } from 'react-intl';
import { DeployButtonsDropdown } from '@xspecs/design-system/src/ui/sidebar/deploy-buttons-dropdown';

export interface MenuLinkItem {
  label: string;
  onClick?: () => void;
}

export type LabelElement = {
  type: 'label';
  text: string;
};

export type SeparatorElement = {
  type: 'separator';
};

export type LinksElement = {
  type: 'links';
  links: MenuLinkItem[];
};

export type TopMenuProps = {
  status?: StatusEnum;
  hideDevMode?: boolean;
  hideLabels?: boolean;
  spaceName?: string;
  organizationName: string;
  hasApps: boolean;
  spaces?: { id: string; name: string; onClick: () => void }[];
  logout: () => void;
  viewSpaces?: () => void;
  onSaveSpaceRename?: (name: string) => void;
  devModeToggle?: (toggle: boolean) => void;
  menuItemSettingsActions: MenuItemSettingsActionsProps;
  triggerCodegen?: () => void;
  triggerImplementation?: () => void;
  triggerDeployment?: () => void;
  cleanFiles?: () => void;
  toggleLogsPanel?: (type: string) => void;
  hideCodeActions?: boolean;
  isModelFile?: boolean;
};

export const TopMenu = (props: TopMenuProps) => {
  const {
    status,
    spaceName,
    spaces = [],
    logout,
    viewSpaces,
    onSaveSpaceRename,
    devModeToggle,
    hideDevMode,
    organizationName,
    hasApps,
    hideLabels,
    menuItemSettingsActions,
    triggerCodegen,
    triggerImplementation,
    triggerDeployment,
    cleanFiles,
    toggleLogsPanel,
    hideCodeActions = false,
    isModelFile,
  } = props;
  const { formatMessage: f } = useIntl();

  const { store } = useStoreContext();
  const showKeyboardModal = store.use.setShowKeyboardShortcutModal();
  const devMode = store.use.devMode();

  const showCodeActions =
    isModelFile &&
    !hideCodeActions &&
    devMode &&
    triggerCodegen &&
    triggerImplementation &&
    triggerDeployment &&
    cleanFiles &&
    toggleLogsPanel;

  // const handleUserGuidesLink = useCallback(() => {
  //   window.open('https://www.narrative.tech/user-guide', '_blank');
  // }, []);

  const handleNarrativeDrivenLink = useCallback(() => {
    window.open('https://www.narrativedriven.org', '_blank');
  }, []);

  const openKeyboardShortcuts = useCallback(() => {
    showKeyboardModal(true);
  }, [showKeyboardModal]);

  const helpMenuItems = useMemo<MenuLinkItem[]>(
    () => [
      // {
      //   label: f({ id: 'user-guide' }),
      //   onClick: handleUserGuidesLink,
      // },
      {
        label: f({ id: 'narrative-driven-guide' }),
        onClick: handleNarrativeDrivenLink,
      },
      {
        label: f({ id: 'keyboard-shortcuts' }),
        onClick: openKeyboardShortcuts,
      },
    ],
    [
      f,
      handleNarrativeDrivenLink,
      // handleUserGuidesLink,
      openKeyboardShortcuts,
    ],
  );

  const toggleDevModeHandler = (checked: boolean) => {
    devModeToggle?.(checked);
  };

  return (
    <div className="w-full h-11 bg-background-grey py-0.5 px-3 flex justify-between border-b">
      <div className="flex py-1 gap-2 flex-1">
        {spaceName ? (
          <SpaceName
            spaceName={spaceName}
            spaces={spaces}
            viewSpaces={viewSpaces}
            onSaveSpaceRename={onSaveSpaceRename}
          />
        ) : null}
        {showCodeActions ? (
          <div className="py-1 ml-1">
            <Separator orientation="vertical" />
          </div>
        ) : null}
        {showCodeActions ? (
          <DeployButtonsDropdown
            triggerCodegen={triggerCodegen}
            triggerDeployment={triggerDeployment}
            triggerImplementation={triggerImplementation}
            cleanFiles={cleanFiles}
            toggleLogsPanel={toggleLogsPanel}
          />
        ) : null}
      </div>
      <div className="flex gap-3 py-2">
        {status ? (
          <Fragment>
            <Status status={status} />
            <Separator orientation="vertical" />
          </Fragment>
        ) : null}
        {hideDevMode ? null : (
          <Fragment>
            <DevMode checked={devMode} onCheckedChange={toggleDevModeHandler} />
            <Separator orientation="vertical" />
          </Fragment>
        )}

        <div className="flex gap-1 items-center">
          <MenuItemHelp items={helpMenuItems} />
          {/*<MenuItemUpdates updates={updates} />*/}
          <MenuItemSettings
            {...menuItemSettingsActions}
            logout={logout}
            organizationName={organizationName}
            spaceName={spaceName}
            hasApps={hasApps}
            hideLabels={hideLabels}
          />
        </div>
      </div>
    </div>
  );
};
