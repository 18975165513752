import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { ModelContext } from '../../ModelContext';
import { EntityChanges } from '../../types';
import { NotificationTypes } from '../../observable/SingleSourceObserver';
import { EntityBase } from '../../entities/EntityBase';
import { UITheme } from '../../interactors/types';

interface ToggleDevModeCommandParams extends IParams {
  toggle: boolean;
}

export class DevModeToggleEvent extends EventBase {
  static eventType = 'ToggleDevModeEvent';

  constructor(
    public readonly params: ToggleDevModeCommandParams,
    public readonly source = ToggleDevModeCommand,
  ) {
    super();
  }
}

export class ToggleDevModeCommand extends CommandBase<ToggleDevModeCommandParams> {
  execute(params: ToggleDevModeCommandParams): DevModeToggleEvent | CommandError | undefined {
    const { toggle } = params;
    this.appState.store.getState().setDevMode(toggle);
    const theme = this.appState.store.getState().theme;
    this.appState.store.getState().setTheme(theme === UITheme.LIGHT ? UITheme.DARK : UITheme.LIGHT);
    if (!ModelContext.hasActiveModel()) return new DevModeToggleEvent(params);
    // const changedEntities: EntityBase[] = this.getModelContext().entityRepository.list();
    // scripts.forEach((script) => {
    //   script.modeHeight = script.calculatedHeight(toggle);
    //   const children = this.getModelContext().entityRepository.getAllUniqueChildren([script.id]);
    //   children.forEach((child) => {
    //     if (script === child) return;
    //     child.modeIsVisible = script.isEntityFilteredForDevMode(child, toggle) ? 'true' : 'false';
    //     changedEntities.push(child);
    //     script.repositionChild(child, toggle);
    //   });
    //   changedEntities.push(script);
    // });
    const changedEntities: EntityBase[] = this.getModelContext().entityRepository.list();
    const changes = changedEntities.map((e) => ({ entity: e, modifiedProperties: [] }));
    const changeSet: EntityChanges = { added: [], updated: changes, deleted: [] };
    this.appState.observer.notify(NotificationTypes.OnTransientChange, changeSet);
  }
}
