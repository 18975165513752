import { SingleSourceModelCanvas } from './canvas/single-source-model-canvas';
import { Stack } from '@mui/material';
import { FileLayout, toast } from '@xspecs/design-system';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { Chat } from './chat/chat';
import { useMutation } from '@apollo/client';
import { SUGGEST_MODEL_MUTATION } from '../../graphql/mutations';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useApplication } from '../../wrappers/application-context/application-context';

type SingleSourceModelProps = {
  id: string;
  fileType: string;
};
export const SingleSourceModel: FC<SingleSourceModelProps> = (props) => {
  const { id, fileType } = props;

  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();
  const { application } = useApplication();

  const [suggestModel] = useMutation(SUGGEST_MODEL_MUTATION);

  const showChatForModel = useSingleSourceStore.use.showChatForModel();

  const onSend = useCallback(
    async (message: string) => {
      const trimmedMessage = message.trim();
      const modelContext = application?.getModelContext();
      if (!trimmedMessage || !workspace || !organization || !modelContext) return;
      const { data } = await suggestModel({
        variables: {
          args: {
            clientId: modelContext.modelFile.activeUser.id,
            modelId: id,
            input: trimmedMessage,
            modelType: fileType,
            workspaceId: workspace.id,
            organizationId: organization.id,
          },
        },
      });
      if (data?.suggestModel.error) {
        toast.error(data.suggestModel.error);
      }
    },
    [application, workspace, organization, suggestModel, id, fileType],
  );

  return (
    <Delayed>
      <FileLayout
        Left={
          <Stack position="relative" width="100%" height="100%">
            <SingleSourceModelCanvas id={id} fileType={fileType} />
          </Stack>
        }
        Right={
          fileType === 'ndem' && showChatForModel ? <Chat onSend={onSend} modelId={id} modelType={fileType} /> : null
        }
      />
    </Delayed>
  );
};

type DelayedProps = {
  children: ReactNode;
  waitBeforeShow?: number;
};

const Delayed = ({ children, waitBeforeShow = 10 }: DelayedProps) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};
