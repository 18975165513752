import { Box, BoxProps } from '@mui/material';
import { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress';
import { LoaderIcon } from 'lucide-react';

type LoadingProps = {
  rootProps?: BoxProps;
  circularProgressProps?: CircularProgressProps;
};

export const Loading = (props: LoadingProps) => {
  const { rootProps = {}, circularProgressProps = {} } = props;

  return (
    <Box
      data-testid="loading"
      className="w-full h-full bg-background-grey"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...rootProps}
    >
      <LoaderIcon className="animate-spin text-primary" />
    </Box>
  );
};
