import { Switch } from '../switch/switch';
import { useIntl } from 'react-intl';

export interface ThemeSwitchProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

export const ThemeSwitch = (props: ThemeSwitchProps) => {
  const { checked, onCheckedChange } = props;
  const { formatMessage: f } = useIntl();

  return (
    <div className="flex gap-1 items-center">
      <span className="text-primary text-sm">{f({ id: 'light' })}</span>
      <Switch checked={checked} onCheckedChange={onCheckedChange} />
      <span className="text-primary text-sm">{f({ id: 'dark' })}</span>
    </div>
  );
};
