import { EntityType, Label } from '@xspecs/single-source-model';
import { FloatingMenuLabelsDetailsButton } from '../floating/menu/labels-details-button/floating-menu-labels-details-button';
import { memo, useCallback, useRef, useState } from 'react';
import { arrow, autoPlacement, offset, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { useCopyEntityLink } from '../hooks/use-copy-entity-link';
import { CopyLinkButton } from '../../../copy-link-button/copy-link-button';
import { DeleteAssetButton } from '../../../delete-asset-button/delete-asset-button';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { useIntl } from 'react-intl';
import { logger } from '@xspecs/logger';
import { NodeToolbar } from '@xyflow/react';
import { Button, Tooltip, TooltipContent, TooltipTrigger } from '@xspecs/design-system';
import { ExternalLink, PanelRightClose } from 'lucide-react';

type FloatingMenuProps = {
  id: string;
  type: string;
  labels: Label[];
  disableLabels?: boolean;
  name: string;
  subType?: string;
  url?: string;
  withCopy?: boolean;
  withDeleteAsset?: boolean;
  onShowDetails: () => void;
  isVisible: boolean;
};

const _FloatingMenu = (props: FloatingMenuProps) => {
  const { id, type, labels, disableLabels, name, subType, url, isVisible, withCopy, withDeleteAsset, onShowDetails } =
    props;
  const { onCopyEntityLink } = useCopyEntityLink();
  const { formatMessage: f } = useIntl();
  const setShowDeleteAssetModal = useSingleSourceStore.use.setShowDeleteAssetModal();
  const setAssetToDelete = useSingleSourceStore.use.setAssetToDelete();

  const openInNewTab = useCallback(() => {
    if (!url) {
      logger.error('No URL provided to open in new tab');
      return;
    }
    window.open(url, '_blank');
  }, [url]);

  const copyEntityLink = useCallback(async () => {
    await onCopyEntityLink(id, type, name);
  }, [onCopyEntityLink, id, type, name]);

  const deleteAsset = useCallback(() => {
    setShowDeleteAssetModal(true);
    setAssetToDelete(id);
  }, [setShowDeleteAssetModal, setAssetToDelete, id]);

  return (
    <NodeToolbar isVisible={isVisible}>
      <div className="flex gap-1 bg-background-grey rounded-lg shadow-md px-2 py-1">
        {withCopy ? <CopyLinkButton onClick={copyEntityLink} /> : null}
        {withDeleteAsset ? <DeleteAssetButton onClick={deleteAsset} /> : null}
        {!disableLabels ? <FloatingMenuLabelsDetailsButton entityId={id} selectedLabels={labels} /> : null}
        {subType === EntityType.Upload ? (
          <Tooltip disableHoverableContent>
            <TooltipTrigger asChild>
              <Button size="icon" onClick={openInNewTab}>
                <ExternalLink />
              </Button>
            </TooltipTrigger>
            <TooltipContent>{f({ id: 'open-in-new-tab' }, { type })}</TooltipContent>
          </Tooltip>
        ) : (
          <Tooltip disableHoverableContent>
            <TooltipTrigger asChild>
              <Button size="icon" variant="ghost" onClick={onShowDetails}>
                <PanelRightClose />
              </Button>
            </TooltipTrigger>
            <TooltipContent>{f({ id: 'show-entity-details' }, { type })}</TooltipContent>
          </Tooltip>
        )}
      </div>
    </NodeToolbar>
  );
};
_FloatingMenu.displayName = 'FloatingMenu';
export const FloatingMenu = memo(_FloatingMenu);

export const useFloatingMenu = () => {
  const [showFloating, setShowFloating] = useState(false);
  const arrowRef = useRef<SVGSVGElement>(null);
  const { floatingStyles, refs, context } = useFloating({
    placement: 'right',
    middleware: [offset(20), autoPlacement({ allowedPlacements: ['right'] }), arrow({ element: arrowRef.current })],
    open: showFloating,
    onOpenChange: setShowFloating,
  });
  const click = useClick(context, { keyboardHandlers: false, toggle: false });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);
  const onManageLabels = useCallback(() => setShowFloating(false), []);
  const onShowFloating = useCallback(() => setShowFloating(true), []);

  return {
    showFloating,
    floatingStyles,
    context,
    arrowRef,
    referenceProps: getReferenceProps(),
    floatingProps: getFloatingProps(),
    onManageLabels,
    referenceRef: refs.setReference,
    floatingRef: refs.setFloating,
    onShowFloating,
  };
};
