import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { EntityChanges } from '../../types';
import { NotificationTypes } from '../../observable/SingleSourceObserver';
import { ModelContext } from '../../ModelContext';
import { EntityBase } from '../../entities/EntityBase';
import { UITheme } from '../../interactors/types';

interface ToggleThemeCommandParams extends IParams {
  theme: UITheme;
}

export class ThemeToggleEvent extends EventBase {
  static eventType = 'ToggleThemeEvent';

  constructor(
    public readonly params: ToggleThemeCommandParams,
    public readonly source = ToggleThemeCommand,
  ) {
    super();
  }
}

export class ToggleThemeCommand extends CommandBase<ToggleThemeCommandParams> {
  execute(params: ToggleThemeCommandParams): ThemeToggleEvent | CommandError | undefined {
    const { theme } = params;
    this.appState.store.getState().setTheme(theme);
    if (!ModelContext.hasActiveModel()) return new ThemeToggleEvent(params);
    const changedEntities: EntityBase[] = this.getModelContext().entityRepository.list();
    const changes = changedEntities.map((e) => ({ entity: e, modifiedProperties: [] }));
    const changeSet: EntityChanges = { added: [], updated: changes, deleted: [] };
    this.appState.observer.notify(NotificationTypes.OnTransientChange, changeSet);
    return new ThemeToggleEvent(params);
  }
}
