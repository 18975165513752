import { IStore } from '../../data/Store';
import { FileStoreClient } from '../../data/FileStoreClient';

export type FollowedUserState = {
  clientId: string;
  viewport?:
    | { x: number; y: number; zoom: number }
    | {
        padding?: number;
        includeHiddenNodes?: boolean;
        minZoom?: number;
        maxZoom?: number;
        duration?: number;
        nodes?: {
          id: string;
        }[];
      };
  color: string;
  name: string;
} | null;

export const DEFAULT_FOLLOWED_USER_STATE: FollowedUserState = null;

export class FollowedUser {
  constructor(
    public readonly store: IStore,
    public readonly fileStoreClient: FileStoreClient,
  ) {
    this.store.getState().setFollowedUser(DEFAULT_FOLLOWED_USER_STATE);
  }

  update({ fileId, clientId }: { fileId: string; clientId: string | null }) {
    // console.log('Follow user update', { fileId, clientId });
    if (!clientId) {
      this.store.getState().setFollowedUser(DEFAULT_FOLLOWED_USER_STATE);
      return;
    }
    const file = this.fileStoreClient.files[fileId];
    if (!file) return;
    if (file.activeUser.id === clientId) return;
    const user = file.activeUsers.find((user) => user.id === clientId);
    // console.log('Found active user', user);
    if (!user) return;
    this.store.getState().setFollowedUser({
      clientId: clientId,
      viewport: user?.viewport,
      color: user.color,
      name: user.name,
    });
  }

  public dispose() {
    this.store.getState().setFollowedUser(DEFAULT_FOLLOWED_USER_STATE);
  }
}
