import { z } from 'zod';
import { EntityType } from '../EntityType';
import { Attributes, EntityBase } from '../EntityBase';
import { Comment } from './Comment';

export class Thread extends EntityBase {
  public constructor(
    id: string,
    public createdBy: string,
    public parent?: EntityBase,
    public position: { x: number; y: number } = { x: 0, y: 0 },
    public scopes: string[] = [],
    public attributes: Attributes = { fontSize: -1, suggested: false },
    public width = 30,
    public height = 30,
    public isVisible = true,
    public zIndex = 0,
  ) {
    super(id, '', parent, position, scopes, attributes, width, height, isVisible, zIndex);
  }

  static version = '1.0.1'; // Rename Tags to Labels;

  private _isResolved: boolean = false;

  static schema = EntityBase.abstractBaseSchema.extend({
    type: z.nativeEnum(EntityType),
    name: z
      .string()
      .optional()
      .describe(
        `
      * The name of the thread
    `,
      )
      .optional(),
    createdBy: z.string().describe(`The user who created the thread`),
    width: z.number().default(30),
    height: z.number().default(30),
    zIndex: z.number().default(7000),
    _isResolved: z.boolean().describe(`Whether the thread is resolved`).optional().default(false),
  });

  static references = ['comments'];

  public comments: Comment[] = [];

  public resolve(): void {
    this._isResolved = true;
  }

  public unresolve(): void {
    this._isResolved = false;
  }

  public addComment(comment: Comment): void {
    if (this.comments.find((c) => c.id === comment.id)) {
      return;
    }
    if (this.comments.length === 0) {
      this.createdBy = comment.createdBy;
    }
    comment.parent = this;
    this.comments.push(comment);
  }

  public removeComment(comment: Comment): void {
    const index = this.comments.findIndex((oldComment) => oldComment.id === comment.id);
    if (index !== -1) {
      this.comments.splice(index, 1);
    }
  }

  get isResolved(): boolean {
    return this._isResolved;
  }

  public ingest(entity: EntityBase): void | string | Error {
    return `${entity.id} not ingested. Target entity does not support ingesting ${entity.type}.`;
  }

  get type(): EntityType {
    return EntityType.Thread;
  }

  static parse(data: unknown): Thread {
    const parsedData = Thread.schema.parse(data);
    const newThread = new this(
      parsedData.id,
      parsedData.createdBy,
      parsedData.parent,
      parsedData.position,
      parsedData.scopes,
      parsedData.attributes,
      parsedData.width,
      parsedData.height,
      parsedData.isVisible,
      parsedData.zIndex,
    );
    newThread._isResolved = parsedData._isResolved;
    return newThread;
  }

  isValid(): boolean {
    return Thread.schema.safeParse(this).success;
  }

  public serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);

    return {
      ...(super.serialize() as any),
      _isResolved: this._isResolved,
      createdBy: this.createdBy,
      comments: this.comments.map((comment) => comment.serialize(true)),
    };
  }
}
