import { default as Editor } from '@monaco-editor/react';
import { monacoOptions } from './monaco.utils';
import { useMonaco } from '../../spec/use-monaco';
import { forwardRef, useImperativeHandle } from 'react';

type SchemaEditorProps = {
  id: string;
};

export type SchemaEditorRef = {
  normalizeValue: (normalizer: (value: string) => string) => void;
  setEditorValue: (value: string) => void;
  getEditorValue: () => string;
};

export const SchemaEditor = forwardRef<SchemaEditorRef, SchemaEditorProps>((props, ref) => {
  const { id } = props;

  const { onMount, normalizeValue, setEditorValue, getEditorValue } = useMonaco({
    id: `${id}-schema-editor`,
    initialValue: '{}',
    placeholder: 'Enter schema here',
  });

  useImperativeHandle(
    ref,
    () => ({
      setEditorValue,
      getEditorValue,
      normalizeValue,
    }),
    [getEditorValue, normalizeValue, setEditorValue],
  );

  return (
    <Editor
      language="json"
      // value={structure}
      options={{ ...monacoOptions, language: 'json', theme: 'vs-dark' }}
      // onChange={onChange}
      onMount={onMount}
    />
  );
});
SchemaEditor.displayName = 'SchemaEditor';
