import { IClipboard } from './IClipboard';

const KEY = 'clipboard';

export class NavigatorClipboard implements IClipboard {
  private readonly isNode: boolean;

  constructor() {
    this.isNode = typeof window === 'undefined' || typeof localStorage === 'undefined';
  }

  public async copy(text: string): Promise<void> {
    if (!this.isNode) localStorage.setItem(KEY, text);
  }

  public async read(): Promise<string> {
    if (!this.isNode) return localStorage.getItem(KEY) ?? '';
    return '';
  }

  public dispose(): void {
    if (!this.isNode) localStorage.removeItem(KEY);
  }
}
