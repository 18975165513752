import { TableCell, TableRow } from '@xspecs/design-system';

export interface ShortcutItemProps {
  command: string;
  name: string;
}

export const ShortcutItem = (props: ShortcutItemProps) => {
  const { command, name } = props;

  return (
    <TableRow className="flex items-center justify-between">
      <TableCell className="text-primary">{command}</TableCell>
      <TableCell className="flex justify-end items-center text-secondary">{name}</TableCell>
    </TableRow>
  );
};
