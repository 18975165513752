import React, { useEffect, useRef, useState } from 'react';
import { editor as monacoEditor, IDisposable, Uri } from 'monaco-editor';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { useUpdate } from './use-update';
import { loader, Monaco } from '@monaco-editor/react';
import { useMount } from './use-mount';
import { logger } from '@xspecs/logger';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

const getOrCreateModel = (uri: string, value: string) => {
  return monacoEditor.getModel(Uri.file(uri)) ?? monacoEditor.createModel(value, uri.split('.').pop(), Uri.file(uri));
};

const uri = 'operation.json';
const createEditor = (ref: HTMLDivElement, options: monacoEditor.IStandaloneEditorConstructionOptions) =>
  monacoEditor.create(ref, options);

type ActualQueryProps = {
  queryId: string;
  value?: string;
  onChange?: (value: string, event: monacoEditor.IModelContentChangedEvent) => void;
};

const defaultValue = `{
  
}`;
export const SeedData = (props: ActualQueryProps) => {
  const { queryId: _queryId, value = defaultValue, onChange } = props;
  const queryId = _queryId + '-seed-data';

  const { formatMessage: f } = useIntl();

  const { application } = useApplication();

  const [isEditorReady, setIsEditorReady] = useState(false);
  const [isMonacoMounting, setIsMonacoMounting] = useState(true);

  const opsRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<monacoEditor.IStandaloneCodeEditor | null>(null);
  const subscriptionRef = useRef<IDisposable>();
  const preventTriggerChangeEvent = useRef<boolean>(false);
  const valueRef = useRef(value);
  const monacoRef = useRef<Monaco | null>(null);

  useMount(() => {
    const cancelable = loader.init();

    cancelable
      .then((monaco) => (monacoRef.current = monaco) && setIsMonacoMounting(false))
      .catch((error) => error?.type !== 'cancelation' && logger.error('Monaco initialization: error:', error));

    return () => (editorRef.current ? disposeEditor() : cancelable.cancel());
  });

  useEffect(() => {
    if (editorRef.current) return;

    const queryModel = getOrCreateModel(uri, valueRef.current);
    if (!opsRef.current) return;
    const editor = createEditor(opsRef.current, {
      model: queryModel,
      language: 'graphql',
      theme: 'default',
      wordWrap: 'on',
      'semanticHighlighting.enabled': true,
      lineNumbers: 'off',
      minimap: { enabled: false },
      scrollBeyondLastLine: false,
      scrollBeyondLastColumn: 0,
      fontFamily: "'JetBrains Mono'",
      scrollbar: {
        horizontal: 'hidden',
        vertical: 'hidden',
      },
      renderLineHighlight: 'none',
      overviewRulerBorder: false,
      glyphMargin: false,
      defaultColorDecorators: false,
      overviewRulerLanes: 0,
      folding: false,
      guides: {
        indentation: false,
      },
      renderWhitespace: 'none',
      quickSuggestions: true,
    });

    // new PlaceholderContentWidget('Type in your query...', editor);
    editorRef.current = editor;
    setIsEditorReady(true);

    return () => {
      if (editorRef.current) {
        editorRef.current.dispose();
        editorRef.current = null;
      }
    };
  }, [application?.context.messageBus, queryId]);

  useUpdate(
    () => {
      if (!editorRef.current || value === undefined) return;
      if (!editorRef.current.getModel()) {
        logger.warn("No model found in the editor. Can't update value.");
        return;
      }
      if (!monacoRef.current) {
        logger.error("Monaco editor isn't ready. Can't update value.");
        return;
      }
      if (editorRef.current.getOption(monacoRef.current.editor.EditorOption.readOnly)) {
        editorRef.current.setValue(value);
      } else if (value !== editorRef.current.getValue()) {
        preventTriggerChangeEvent.current = true;
        editorRef.current.executeEdits('', [
          {
            range: editorRef.current.getModel()!.getFullModelRange(),
            text: value,
            forceMoveMarkers: true,
          },
        ]);

        editorRef.current.pushUndoStop();
        preventTriggerChangeEvent.current = false;
      }
    },
    [value],
    isEditorReady,
  );

  valueRef.current = value;
  useEffect(() => {
    if (onChange) {
      subscriptionRef.current?.dispose();
      subscriptionRef.current = editorRef.current?.onDidChangeModelContent((event) => {
        if (!preventTriggerChangeEvent.current) {
          onChange(editorRef.current!.getValue(), event);
        }
      });
    }
  }, [onChange]);

  function disposeEditor() {
    subscriptionRef.current?.dispose();
    editorRef.current!.getModel()?.dispose();
    editorRef.current!.dispose();
  }

  return <Box ref={opsRef} border="1px solid #E0E0E0" borderRadius={2} flexGrow={1} />;
};
