import { cn } from '@xspecs/design-system';
import { CSSProperties } from 'react';

type OverlayProps = {
  className?: string;
  overrideStyles?: CSSProperties;
};

export const Overlay = (props: OverlayProps) => {
  const { className, overrideStyles } = props;
  return (
    <div
      className={cn('absolute inset-0 z-[999] pointer-events-none', OVERLAY_COLOR, className)}
      style={overrideStyles}
    />
  );
};
export const OVERLAY_COLOR = 'bg-white/50';
