import { Avatar, Avatars } from '../../../avatars/avatars';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { useCallback, useMemo } from 'react';
import { useCommandDispatch } from '@xspecs/design-system';

export const SingleSourceModelToolbarAvatars = ({ id }: { id: string }) => {
  const activeUsers = useSingleSourceStore.use.activeUsersByFile()[id];
  const { dispatchCommand } = useCommandDispatch();

  const users = useMemo<Avatar[]>(() => {
    if (!activeUsers) return [];
    return activeUsers.map((user) => ({
      id: user.id,
      sub: user.sub,
      name: user.name,
      picture: user.picture,
      color: user.color,
    }));
  }, [activeUsers]);

  const onAvatarClick = useCallback(
    (userId: string) => {
      dispatchCommand('FollowUserCommand', { clientId: userId, fileId: id });
    },
    [dispatchCommand, id],
  );

  return <Avatars users={users} onAvatarClick={onAvatarClick} />;
};
