import { IStore } from '../../data/Store';
import { EntityChanges } from '../../types';
import { EntityBase } from '../../entities/EntityBase';
import { AssetBase } from '../../entities/assets/AssetBase';
import { Attachment } from '../../entities/assets/Attachment';
import { ConstructBase } from '../../entities/constructs/ConstructBase';

export type SuggestionsTreeState = {
  rootId: string;
  tree: {
    id: string;
    parent: string;
    text: string;
    data: {
      icon: string;
    };
  }[];
};

export const DEFAULT_SUGGESTIONS_TREE_STATE: SuggestionsTreeState = {
  rootId: 'root',
  tree: [],
};

export class SuggestionsTree {
  suggestedEntities: Record<string, EntityBase> = {};

  constructor(private readonly store: IStore) {
    this.store.getState().setSuggestionsTree({
      ...DEFAULT_SUGGESTIONS_TREE_STATE,
    });
  }

  applyChange(changes: EntityChanges = { added: [], updated: [], deleted: [] }) {
    changes.deleted.forEach((entity) => {
      delete this.suggestedEntities[entity.id];
    });
    changes.added.forEach((entity) => this.handleEntity(entity));
    changes.updated.forEach((update) => this.handleEntity(update.entity));
    this.updateStore();
  }

  handleEntity(entity: EntityBase) {
    if (!entity.attributes.suggested) {
      delete this.suggestedEntities[entity.id];
      return;
    }
    if (this.isEntityIgnored(entity)) {
      return;
    }
    this.suggestedEntities[entity.id] = entity;
  }

  private isEntityIgnored(entity: EntityBase) {
    return (
      entity instanceof AssetBase || entity instanceof Attachment || (entity instanceof ConstructBase && !entity.script)
    );
  }

  private updateStore() {
    const tree = Object.values(this.suggestedEntities)
      .sort((a, b) => {
        return Date.parse(a.attributes.createdAt ?? '') - Date.parse(b.attributes.createdAt ?? '');
      })
      .map((entity) => ({
        id: entity.id,
        parent: entity.parent?.id ?? 'root',
        text: entity.name,
        data: {
          icon: entity.type.toLowerCase(),
        },
      }));

    this.store.getState().setSuggestionsTree({
      rootId: 'root',
      tree,
    } satisfies SuggestionsTreeState);
  }

  dispose() {
    this.suggestedEntities = {};
    this.store.getState().setSuggestionsTree({
      ...DEFAULT_SUGGESTIONS_TREE_STATE,
    } satisfies SuggestionsTreeState);
  }
}
