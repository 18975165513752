import { MessageBus } from '../../commands/framework/MessageBus';
import { SpaceFileTreeChangedEvent } from '../../commands/spaces/BroadcastRemoteFileTreeChangesCommand';
import { FileTree } from './FileTree';
import { NewFileCreatedEvent } from '../../commands/fileTree/CreateNewFileCommand';
import { FileTreeItemRenamedEvent } from '../../commands/fileTree/RenameFileTreeItemCommand';
import { FileTreeItemDeletedEvent } from '../../commands/fileTree/DeleteFileTreeItemCommand';
import { DevModeToggleEvent } from '../../commands/ide/ToggleDevModeCommand';

export class FileTreeProjection {
  constructor(
    private readonly messageBus: MessageBus,
    private readonly fileTree: FileTree,
  ) {
    this.messageBus.subscribe(
      [SpaceFileTreeChangedEvent, NewFileCreatedEvent, FileTreeItemRenamedEvent, DevModeToggleEvent],
      (event: SpaceFileTreeChangedEvent | NewFileCreatedEvent | FileTreeItemRenamedEvent | DevModeToggleEvent) => {
        this.fileTree.setLoading(true);
        if (event instanceof DevModeToggleEvent) {
          this.fileTree.updateStore();
        } else if (event instanceof SpaceFileTreeChangedEvent) {
          event.params.added.forEach((item) => {
            this.fileTree.applyChange(item);
          });
          event.params.updated.forEach((item) => {
            this.fileTree.applyChange(item);
          });
          event.params.deleted.forEach((id) => {
            this.fileTree.delete({ id });
          });
        } else {
          event.params.items.forEach((item) => {
            this.fileTree.applyChange(item);
          });
        }
        this.fileTree.setLoading(false);
      },
    );
    this.messageBus.subscribe([FileTreeItemDeletedEvent], (event: FileTreeItemDeletedEvent) => {
      this.fileTree.setLoading(true);
      this.fileTree.delete({ id: event.params.id });
    });
  }
}
