import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { useEffect, useMemo, useRef } from 'react';
import { DropTargetNodeData } from '@xspecs/single-source-model';

export const DropTargetNode = (props: NodeProps<Node<DropTargetNodeData>>) => {
  const { data } = props;
  const nodeRef = useRef<HTMLDivElement>(null);

  const isRejected = data.subType === 'rejected';
  const isActual = data.subType === 'actual';

  const color = useMemo(() => {
    return isActual ? 'rgba(0,255, 0, 1)' : 'rgba(255, 0, 0, 0.5)';
  }, [isActual]);

  const sizeStyle = useMemo(
    () => ({
      width: `${data.width}px`,
      height: `${data.height}px`,
    }),
    [data.width, data.height],
  );

  const borderStyle = useMemo(
    () => ({
      ...sizeStyle,
      border: `3px dashed ${color}`,
    }),
    [sizeStyle, color],
  );

  const handleStyle = useMemo(() => ({ backgroundColor: color }), [color]);

  useEffect(() => {
    if (nodeRef.current?.parentElement) {
      nodeRef.current.parentElement.style.pointerEvents = 'none';
    }
  }, []);

  return (
    <>
      <div
        style={sizeStyle}
        className={`${data.entityType} absolute opacity-50 rounded-md pointer-events-none nodrag`}
      />
      <div
        id={data.id}
        ref={nodeRef}
        style={borderStyle}
        className="absolute flex items-center justify-center rounded-md pointer-events-none nodrag"
      />

      <Handle type="source" position={Position.Top} style={handleStyle} />
      <Handle type="source" position={Position.Bottom} style={handleStyle} />
      <Handle type="source" position={Position.Left} style={handleStyle} />
      <Handle type="source" position={Position.Right} style={handleStyle} />
    </>
  );
};
