import { NodeModel } from '@minoru/react-dnd-treeview';
import { FileTreeItem } from '@xspecs/single-source-model';
import { ChangeEvent, KeyboardEvent, MouseEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { clsx } from 'clsx';
import { cn } from '../../../utils';
import { Checkbox, Icon } from '@xspecs/design-system';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../dropdown-menu/dropdown-menu';
import { useIntl } from 'react-intl';
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from '../../context-menu/context-menu';
import { CheckedState } from '@radix-ui/react-checkbox';

type NodeProps = {
  node: FileTreeItem;
  depth: number;
  isOpen: boolean;
  onToggle: (id: NodeModel['id']) => void;
  onRenameItem?: (id: string, name: string) => void;
  onDeleteItem?: (id: string) => void;
  onSelectFile: (file: NodeModel) => void;
  isDropTarget: boolean;
  onCheckTreeItem?: (id: string, checked: boolean) => void;
};

const _TreeViewNode = (props: NodeProps) => {
  const {
    node,
    onRenameItem: onRenameItemProp,
    onSelectFile,
    onToggle,
    isOpen,
    isDropTarget,
    depth,
    onDeleteItem,
    onCheckTreeItem,
  } = props;
  const { data, id } = node;
  const indent = depth * 24;

  const { formatMessage: f } = useIntl();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [newFile, setNewFile] = useState(node.text);
  const [hasTriggered, setHasTriggered] = useState(false);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNewFile(event.target.value);
  }, []);

  const onRenameItem = useCallback(
    (id: string, name: string) => {
      onRenameItemProp?.(id, name);
      setIsEditMode(false);
    },
    [onRenameItemProp],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if ((event.key === 'Enter' || event.key === 'Escape') && id) {
        onRenameItem(id, newFile);
      }
    },
    [id, newFile, onRenameItem],
  );

  const onBlur = useCallback(() => {
    if (!id) return;
    onRenameItem(id, newFile);
  }, [id, newFile, onRenameItem]);

  const onClickItem = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (node.droppable) return onToggle(node.id);
      onSelectFile(node);
    },
    [node, onSelectFile, onToggle],
  );

  const onEdit = useCallback((event) => {
    event.stopPropagation();
    setIsEditMode(true);
    setTimeout(() => {
      const isRightEdgeOverflown = inputRef.current
        ? inputRef.current?.scrollWidth > inputRef.current?.clientWidth
        : false;

      inputRef.current?.focus({ preventScroll: !isRightEdgeOverflown });
    }, 10);
  }, []);

  const onDelete = useCallback(
    (event) => {
      event.stopPropagation();
      onDeleteItem?.(id);
    },
    [id, onDeleteItem],
  );

  const onCheckedChange = useCallback(
    (checkedState: CheckedState) => {
      onCheckTreeItem?.(id, typeof checkedState !== 'string' && checkedState);
    },
    [id, onCheckTreeItem],
  );

  const onCheckClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {
    let timer;

    if (isDropTarget && !hasTriggered && !isOpen) {
      timer = setTimeout(() => {
        onToggle(node.id);
        setHasTriggered(true);
      }, 700);
    }

    return () => {
      clearTimeout(timer);
      setHasTriggered(false);
    };
  }, [isDropTarget, hasTriggered, isOpen, onToggle, node.id]);

  useEffect(() => {
    setNewFile(node.text);
  }, [node.text]);

  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <div
          style={{ paddingInlineStart: indent }}
          className={clsx(
            'flex p-1 rounded-md items-center gap-1 hover:bg-accent group/item w-full',
            (data?.isActive || isMenuOpen) && 'bg-accent',
          )}
          data-testid={`tree-view-node-${node.text}`}
          onClick={onClickItem}
          onDoubleClick={onEdit}
        >
          {onCheckTreeItem ? (
            <Checkbox className="ml-1" onCheckedChange={onCheckedChange} onClick={onCheckClick} />
          ) : null}
          <div className="relative grow-1 flex items-center">
            <div>
              {node.droppable ? (
                <div>
                  <ChevronRight className={`size-4 text-icon ml-1 ${isOpen ? 'rotate-90' : ''}`} />
                </div>
              ) : (
                <div className={cn('w-4 h-4', node.parent ? 'ml-1' : 'ml-0')} />
              )}
            </div>
            <Icon
              name={data?.icon || ''}
              width={16}
              height={16}
              color={data?.iconProps?.backgroundColor}
              strokeWidth={2}
            />
            {isEditMode ? (
              <input
                type="text"
                className="grow-1 h-full text-sm ml-1 leading-3.5 text-secondary outline-0 w-full inline-block"
                ref={inputRef}
                value={newFile}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                placeholder={f({ id: 'untitled' })}
                size={newFile.length}
              />
            ) : (
              <span
                className={clsx(
                  'text-sm h-full leading-3.5 ml-1 group-hover/item:text-primary whitespace-nowrap',
                  data?.isActive ? 'text-primary font-medium' : 'text-secondary',
                )}
              >
                {node.text || f({ id: 'untitled' })}
              </span>
            )}
          </div>
          {!onRenameItemProp && !onDeleteItem ? null : (
            <DropdownMenu open={isMenuOpen} onOpenChange={setIsMenuOpen}>
              <DropdownMenuTrigger
                className={clsx(
                  isMenuOpen ? 'opacity-100' : 'opacity-0',
                  isMenuOpen && 'bg-base-primary',
                  'rounded-sm group-hover/item:opacity-100',
                )}
              >
                <Icon name="more-horiz" color={isMenuOpen ? 'white' : undefined} height={16} width={16} />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start">
                {onRenameItemProp ? <DropdownMenuItem onClick={onEdit}>{f({ id: 'rename' })}</DropdownMenuItem> : null}
                {onDeleteItem ? <DropdownMenuItem onClick={onDelete}>{f({ id: 'delete' })}</DropdownMenuItem> : null}
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </ContextMenuTrigger>
      {!onRenameItemProp && !onDeleteItem ? null : (
        <ContextMenuContent>
          {onRenameItemProp ? <ContextMenuItem onClick={onEdit}>{f({ id: 'rename' })}</ContextMenuItem> : null}
          {onDeleteItem ? <ContextMenuItem onClick={onDelete}>{f({ id: 'delete' })}</ContextMenuItem> : null}
        </ContextMenuContent>
      )}
    </ContextMenu>
  );
};
export const TreeViewNode = memo(_TreeViewNode);
