import { NavLink } from 'react-router-dom';
import { RoutePaths } from '../../config/route-paths/route-paths';
import { useIntl } from 'react-intl';
import { Icon } from '@xspecs/design-system';

export const AuthRedirect = () => {
  const { formatMessage: f } = useIntl();

  return (
    <div className="w-full h-full flex flex-col bg-background-grey">
      <div
        data-testid="auth-redirect-header"
        className="w-full h-11 bg-background-grey py-0.5 px-3 flex justify-between items-center border-b"
      >
        <Icon name="on-auto-icon" width={24} height={24} />
      </div>
      <div className="flex flex-col items-center my-auto" data-testid="auth-redirect">
        <Icon name="on-auto-icon" height={40} width={40} />
        <h5 className="text-primary my-4 text-2xl" data-testid="auth-redirect-redirect-text">
          {f({ id: 'redirecting' })}
        </h5>
        <p className="text-primary w-2xs text-center">
          <NavLink
            data-testid="auth-redirect-click-text"
            to={RoutePaths.ChooseOrganization}
            className="text-base-primary"
          >
            {f({ id: 'redirect-action' })}
          </NavLink>
        </p>
      </div>
    </div>
  );
};
