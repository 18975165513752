import { useMutation } from '@apollo/client';
import { useAuth } from '../../auth';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavBar } from '../../components/nav-bar/nav-bar';
import { RoutePaths } from '../../config/route-paths/route-paths';
import { VERIFY_EMAIL_MUTATION } from '../../graphql/mutations';
import { Button, Icon } from '@xspecs/design-system';
import { Check } from 'lucide-react';

export const ConfirmEmail = () => {
  const [emailSent, setEmailSent] = useState(false);

  const [sendVerificationEmail] = useMutation(VERIFY_EMAIL_MUTATION);

  const { logout } = useAuth();

  const { formatMessage: f } = useIntl();

  const resendEmail = useCallback(async () => {
    const response = await sendVerificationEmail();
    if (!response.data?.verifyEmail?.error) {
      setEmailSent(true);
    }
  }, [sendVerificationEmail]);

  const goToLogin = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } });
  }, [logout]);

  return (
    <div className="w-full h-full flex flex-col bg-background-grey">
      <NavBar isLoggedIn isPrimary hideOrganizations hideTextLogo />
      <div className="flex flex-col items-center my-auto">
        <Icon name="on-auto-icon" height={40} width={40} />
        <h5 className="text-primary my-4 text-2xl">{f({ id: 'verify-to-continue' })}</h5>
        <p className="text-primary w-2xs text-center">{f({ id: 'ask-to-click-link' })}</p>
        {emailSent ? (
          <div className="text-semantic-warning flex gap-1 mt-2 items-center" onClick={resendEmail}>
            <Check size={20} />
            <span>{f({ id: 'email-resent' })}</span>
          </div>
        ) : (
          <Button variant="link" onClick={resendEmail} className="mt-1">
            {f({ id: 'resent-email-message' })}
          </Button>
        )}
        <Button variant="link" onClick={goToLogin}>
          {f({ id: 'go-to-login' })}
        </Button>
      </div>
    </div>
  );
};
