import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { FileType } from '../../data/File.types';
import { logger } from '@xspecs/logger';

interface UpdateFileContentCommandParams extends IParams {
  fileId: string;
  fileType: FileType;
  content: string;
}

export class FileContentUpdatedEvent extends EventBase {
  static eventType = 'FileContentUpdatedEvent';

  constructor(
    public readonly params: UpdateFileContentCommandParams,
    public readonly source = UpdateFileContentCommand,
  ) {
    super();
  }
}

const connectedFiles = new Set<string>();
export class UpdateFileContentCommand extends CommandBase<UpdateFileContentCommandParams> {
  execute(params: UpdateFileContentCommandParams): FileContentUpdatedEvent | CommandError {
    const { fileId, content, fileType } = params;

    if (fileType !== FileType.Spec) {
      throw new Error('Only monaco editor content can be overwritten');
    }

    if (this.appState.fileStoreClient.files[fileId]) {
      throw new Error(`File ${fileId} already exists`);
    }

    connectedFiles.add(fileId);
    const file = this.appState.fileStoreClient.loadFile({
      fileId,
      type: fileType,
      onSynced: () => {
        if (!file.document) {
          logger.error(`Document for ${fileId} not found`);
          return;
        }
        const ytext = file.document.getText('content');
        ytext.delete(0, ytext.length);
        ytext.insert(0, content);
        this.appState.fileStoreClient.removeFile(fileId);
        connectedFiles.delete(fileId);
      },
    });
    return new FileContentUpdatedEvent({
      ...params,
    });
  }
}
