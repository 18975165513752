import {
  Attachment,
  AttachmentType,
  CreateUploadCommand,
  Query,
  UpdateQueryUploadCommand,
  Upload,
  UploadType,
} from '@xspecs/single-source-model';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { ChangeEvent, HTMLAttributes, useCallback, useMemo, useRef } from 'react';
import { Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useApplication } from '../../../../wrappers/application-context/application-context';
import { sid } from '@xspecs/short-id';
import { resizeImage } from '../../../../utils/resizeImage';
import { FILE_UPLOAD_URLS_QUERY } from '../../../../graphql/queries';
import { useLazyQuery } from '@apollo/client';
import { useSnackStack } from '../../../../wrappers/snack-stack-context';
import { getImageDimensions } from '../hooks/use-image-dimensions';
import { useReactiveState } from '../../../../hooks/use-reactive-state';

const filter = createFilterOptions<AssetOption>();

type UploadAssetsProps = {
  queryId: string;
};

export const UploadAssets = (props: UploadAssetsProps) => {
  const { queryId } = props;

  const { formatMessage: f } = useIntl();

  const { application } = useApplication();

  const [getPreSignedUrl] = useLazyQuery(FILE_UPLOAD_URLS_QUERY, { fetchPolicy: 'no-cache' });
  const { addToast } = useSnackStack();

  const _assets = useReactiveState(Attachment.dataSources[AttachmentType.Upload]) as Upload[];
  const assets = _assets.filter((upload: Upload) => upload.subType === UploadType.Image);

  const inputRef = useRef<HTMLInputElement>(null);

  const query = useMemo(() => {
    return application?.getModelContext()?.entityRepository.get<Query>(queryId);
  }, [application, queryId]);

  const option = useMemo(() => {
    if (!query?.upload) return null;
    return {
      id: query.upload.id,
      title: query.upload.name,
      url: query.upload.url,
      width: query.upload.width,
      height: query.upload.height,
    } satisfies AssetOption;
  }, [query?.upload]);

  const options: AssetOption[] = assets.map(
    (asset) =>
      ({
        id: asset.id,
        url: asset.url,
        title: asset.name,
        width: asset.width,
        height: asset.height,
      }) satisfies AssetOption,
  );

  const onChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;
      const assetId = sid();
      const { data } = await getPreSignedUrl({ variables: { parentId: assetId } });

      if (!data?.signedMediaUploadUrl) {
        addToast({
          message: f({ id: 'failed-to-get-signed-media-upload-url' }),
          severity: 'error',
        });
        return;
      }
      const uploadFileResult = await fetch(data.signedMediaUploadUrl.putUrl, {
        method: 'PUT',
        headers: { 'Content-Type': file.type || 'application/octet-stream' },
        body: file,
      });
      if (!uploadFileResult.ok) {
        addToast({
          message: f({ id: 'failed-to-upload-file' }),
          severity: 'error',
        });
        return;
      }
      let imgDimensions;
      let originalDimensions;

      try {
        originalDimensions = await getImageDimensions(file);
      } catch (error) {
        originalDimensions = {};
      } finally {
        imgDimensions = resizeImage(originalDimensions);
      }

      application?.context.messageBus.send(CreateUploadCommand, {
        assetId,
        id: queryId,
        name: file.name,
        position: { x: 0, y: 0 },
        type: UploadType.Image,
        url: data.signedMediaUploadUrl.getUrl,
        metadata: {
          title: file.name,
          imageUrl: data.signedMediaUploadUrl.getUrl,
          width: imgDimensions.width,
          height: imgDimensions.height,
        },
      });
    },
    [addToast, application?.context.messageBus, f, getPreSignedUrl, queryId],
  );

  return (
    <>
      <Autocomplete
        value={option}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // setValue({ title: newValue });
            inputRef.current?.click();
          } else if (newValue && newValue.inputValue) {
            // setValue({ title: newValue.inputValue });
            inputRef.current?.click();
          } else {
            if (newValue) {
              application?.context.messageBus.send(UpdateQueryUploadCommand, {
                id: queryId,
                uploadId: newValue.id,
              });
            } else {
              application?.context.messageBus.send(UpdateQueryUploadCommand, {
                id: queryId,
              });
            }
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.title);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue || 'New'}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="UploadAssets"
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        renderOption={(props: HTMLAttributes<HTMLLIElement> & { key: string }, option) => {
          const { key, ...rest } = props;

          return (
            <li
              key={option.id ?? 'UploadAssetsNoKey'}
              style={{
                display: 'block',
                height: 60,
                padding: '12 0',
              }}
              {...rest}
            >
              <Stack
                direction="row"
                height="100%"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
              >
                <Typography title={option.title} width="70%" noWrap>
                  {option.title}
                </Typography>
                {option.url ? (
                  <img
                    src={option.url}
                    alt={option.title}
                    style={{
                      width: '25%',
                      aspectRatio: `${option.width}/${option.height}`,
                      objectFit: 'contain',
                      borderRadius: 4,
                    }}
                  />
                ) : null}
              </Stack>
            </li>
          );
        }}
        sx={{
          width: 300,
          backgroundColor: 'white',
        }}
        freeSolo
        renderInput={(params) => <TextField {...params} label={f({ id: 'select-or-upload-image' })} />}
      />
      <input ref={inputRef} type="file" style={{ display: 'none' }} onChange={onChange} />
    </>
  );
};

interface AssetOption {
  id?: string;
  inputValue?: string;
  title: string;
  url?: string;
  width?: number;
  height?: number;
}
