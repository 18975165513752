import { DndProvider } from 'react-dnd';
import { getBackendOptions, MultiBackend, NodeModel, Tree } from '@minoru/react-dnd-treeview';
import styles from './tree-view.module.css';
import { FileTreeItem } from '@xspecs/single-source-model';
import { TreeViewNode } from './tree-view-node/tree-view-node';

export interface TreeViewProps {
  rootId: string;
  files: NodeModel[];
  onRenameItem?: (id: string, name: string) => void;
  onDeleteItem?: (id: string) => void;
  onSelectFile: (file: NodeModel) => void;
  onCheckTreeItem?: (id: string, checked: boolean) => void;
  disabled?: boolean;
}

export const TreeView = (props: TreeViewProps) => {
  const { files, onRenameItem, disabled, rootId, onSelectFile, onDeleteItem, onCheckTreeItem } = props;
  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <div className="overflow-y-auto w-full h-full px-1 py-0.5 flex">
        <Tree
          tree={files}
          rootId={rootId}
          sort={() => 1}
          canDrag={() => !disabled}
          canDrop={() => !disabled}
          render={(node: FileTreeItem, { depth, isOpen, onToggle, isDropTarget }) => (
            <TreeViewNode
              node={node}
              depth={depth}
              isOpen={isOpen}
              onToggle={onToggle}
              onRenameItem={onRenameItem}
              onSelectFile={onSelectFile}
              isDropTarget={isDropTarget}
              onDeleteItem={onDeleteItem}
              onCheckTreeItem={onCheckTreeItem}
            />
          )}
          onDrop={(...args) => {}}
          classes={{
            root: styles.treeRoot,
            draggingSource: styles.draggingSource,
            dropTarget: styles.dropTarget,
          }}
        />
      </div>
    </DndProvider>
  );
};
