import { useCallback, useMemo } from 'react';
import { Button, useCommandDispatch } from '@xspecs/design-system';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useIntl } from 'react-intl';
import { Loader } from 'lucide-react';

export const FollowingUserOverlay = ({ fileId }: { fileId: string }) => {
  const followed = useSingleSourceStore.use.followedUser();

  const { formatMessage: f } = useIntl();
  const { dispatchCommand } = useCommandDispatch();

  const onClick = useCallback(() => {
    dispatchCommand('FollowUserCommand', { fileId, clientId: null });
  }, [dispatchCommand, fileId]);

  const rootStyle = useMemo(
    () => ({
      border: `3px solid ${followed?.color ?? 'transparent'}`,
    }),
    [followed?.color],
  );

  const headerContainerStyle = useMemo(() => ({ backgroundColor: followed?.color }), [followed?.color]);

  return (
    <div
      className="size-full absolute top-0 left-0 bg-transparent flex items-start justify-center z-10 pointer-events-none"
      style={rootStyle}
    >
      {followed ? (
        <div
          className="flex items-center justify-center gap-1 bg-gray-200 rounded-b-md w-fit px-2 py-1 relative -top-1"
          style={headerContainerStyle}
        >
          <span className="text-sm text-black whitespace-nowrap">
            {f({ id: 'following-user' }, { name: followed?.name })}
          </span>
          <Button
            className="pointer-events-auto py-0 px-0 h-[24px] w-[50px] flex-shrink-0"
            variant="outline"
            onClick={onClick}
          >
            {f({ id: 'stop' })}
          </Button>
          {followed.name === 'AI' ? <Loader className="animate-spin text-black" /> : null}
        </div>
      ) : null}
    </div>
  );
};
