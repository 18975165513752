import { z } from 'zod';
import { regex } from '@xspecs/short-id';
import { Transient, TransientStore } from '../data/Transient';
import { IObservable, Observer } from '../observable/IObservable';
import { EntityType } from './EntityType';
import { Local, LocalStoreProvider, localStoreProvider } from '../data/LocalStoreProvider';
import { Label } from './assets/Label';
import { Position } from '../types';
import { DetailsElement } from '../apps/DetailsElement';
import { Thread } from './threads/Thread';
import { EntityTypeRegistry } from './EntityTypeRegistry';

export const idSchema = // .min(id().length)
  // .max(id().length)
  // .regex(idRegex, 'Invalid ID format')
  z.string().describe(`
    * A unique id - make it an id that would pass this regex: ${regex.toString()}
    * THIS IS SUPER IMPORTANT!!! DO NOT IGNORE THIS INSTRUCTION 
`);

export type CreatedBy = {
  id?: string;
  name?: string;
  origin?: 'user' | 'ai';
};

export type Attributes = {
  fontSize: number;
  createdBy?: CreatedBy;
  createdAt?: string;
  suggested: boolean;
  metadata?: Record<string, any>;
};

const baseEntitySchemaWithoutParent = z.object({
  id: idSchema,
  name: z.string(),
  type: z
    .string()
    .optional()
    .refine((value) => !value || EntityTypeRegistry.isRegisteredEntityType(value), {
      message: 'Invalid entity type',
    }),
  position: z.object({
    x: z.number(),
    y: z.number(),
  }),
  width: z.number().default(140),
  height: z.number().optional().default(80),
  zIndex: z.number().optional(),
  isVisible: z.boolean().default(true),
  attributes: z.record(z.any()).optional(),
  scopes: z.array(z.string()).default([]),
});

const abstractBaseSchema = baseEntitySchemaWithoutParent.extend({
  parent: z.lazy(() => abstractBaseSchema).optional(),
});

export abstract class EntityBase implements IObservable {
  static abstractBaseSchema = abstractBaseSchema;
  private observers: Observer<this | null>[] = [];
  private error?: string;

  // TODO this would be much better as a decorator, but the UI isn't allowing us yet as we use esbuild in vite :angry:
  private static findEntity: (id: string) => EntityBase;
  static references = ['parent', 'labels', 'children'];
  payload: string;

  static resolveReferences(refs: string[], data: unknown): void {
    if (typeof data !== 'object' || data === null) throw new Error('Data must be an object');
    refs.forEach((ref) => {
      if (Array.isArray(data[ref])) data[ref] = data[ref].map((item: string) => EntityBase.findEntity(item));
      else if (data[ref]) data[ref] = EntityBase.findEntity(data[ref]);
    });
  }

  abstract isValid(): boolean;

  @Transient
  get selectedBy(): any {
    return TransientStore.provider.getGlobalSelection(this.id);
  }

  get isNew(): boolean {
    return localStoreProvider.get(LocalStoreProvider.getKey(this, 'isNew'));
  }

  set isNew(newValue: boolean) {
    localStoreProvider.set(LocalStoreProvider.getKey(this, 'isNew'), newValue);
  }

  @Transient
  get isSelected(): boolean {
    return !!TransientStore.provider.getLocalSelection(this.id);
  }

  set isSelected(newValue: boolean) {
    TransientStore.provider.setBoolean(this.id, newValue, 'selected');
  }

  setIsSelectedSync(newValue: boolean) {
    TransientStore.provider.setBoolean(this.id, newValue, 'selected', true);
  }

  select(sync?: boolean): void {
    if (sync) this.setIsSelectedSync(true);
    else this.isSelected = true;
  }

  deselect(sync?: boolean): void {
    if (sync) this.setIsSelectedSync(false);
    else this.isSelected = false;
  }

  @Local
  get isFiltered(): boolean {
    return localStoreProvider.get(LocalStoreProvider.getKey(this, 'isFiltered')) ?? true;
  }

  // @Local
  set isFiltered(newValue: boolean) {
    if (this.isFiltered === newValue) return;
    this.notify();
    localStoreProvider.set(LocalStoreProvider.getKey(this, 'isFiltered'), newValue);
  }

  @Local
  get blink(): any {
    return localStoreProvider.get(LocalStoreProvider.getKey(this, 'blink'));
  }

  set blink(newValue: any) {
    localStoreProvider.set(LocalStoreProvider.getKey(this, 'blink'), newValue);
    this.notify();
  }

  @Local
  // @Transient
  get isDragging(): any {
    return localStoreProvider.get(LocalStoreProvider.getKey(this, 'isDragging'));
  }

  set isDragging(newValue: any) {
    this.notify();
    localStoreProvider.set(LocalStoreProvider.getKey(this, 'isDragging'), newValue);
  }

  public applyError(error: string): boolean {
    this.error = error;
    return false;
  }

  public getError(): Error | undefined {
    if (this.error) {
      const error = new Error(this.error);
      this.resetError();
      return error;
    }
    return undefined;
  }

  public resetError(): void {
    this.error = undefined;
  }

  public dimensions(): { width: number; height: number; position: { x: number; y: number } } {
    return { width: this.width, height: this.height, position: this.position };
  }

  get visibleParent(): any {
    if (this?.parent) {
      return this.parent.visibleParent;
    }

    return null;
  }

  protected static excludedProperties = new Set<string>(['error', '_detailsPane', '_children']);

  protected static parseBase<T extends EntityBase>(
    this: new (...args: any[]) => T,
    data: unknown,
    schema: z.ZodSchema<any> = EntityBase.abstractBaseSchema,
    references: string[] = [],
  ): T {
    if (data instanceof this) return data;
    const validatedData = EntityBase.validateData(data, references);
    const position = {
      x: validatedData.position?.x ?? 0,
      y: validatedData.position?.y ?? 0,
    };
    const attributes = {
      fontSize: validatedData.attributes?.fontSize,
      annotationMetadata: validatedData.attributes?.annotationMetadata,
      createdBy: validatedData.attributes?.createdBy,
      suggested: validatedData.attributes?.suggested,
    };
    const entity = new this(
      validatedData.id,
      validatedData.name,
      validatedData.parent,
      position,
      validatedData.scopes,
      attributes,
      validatedData.width,
      validatedData.height,
      validatedData.isVisible,
      validatedData.zIndex,
    );
    if (schema) {
      const validatedSchema = schema.parse(data);
      EntityBase.copyParsedValidatedData(validatedSchema, entity);
    }
    return entity;
  }

  private static copyParsedValidatedData<T extends EntityBase>(from: any, to: T): T {
    Object.keys(from).forEach((key) => {
      if (key !== 'type') {
        to[key] = from[key];
      }
    });
    return to as T;
  }

  protected static validateData(data: unknown, references: string[] = []): any {
    EntityBase.resolveReferences([...EntityBase.references, ...references], data);
    return EntityBase.abstractBaseSchema.parse(data);
  }

  public constructor(
    public id: string,
    public name: string,
    public parent?: EntityBase,
    public position: Position = { x: 0, y: 0 },
    public scopes: string[] = [],
    public attributes: Attributes = { fontSize: -1, suggested: false },
    public width = 140,
    public height = 80,
    public isVisible = true,
    public zIndex = 0,
  ) {}

  public abstract type: EntityType | string;

  public children: EntityBase[] = [];

  public labels: Label[] = [];

  public removeLabel(label: Label): void {
    this.labels = this.labels.filter((t) => t !== label);
    this.notify();
  }

  public observe(observer: Observer<this>): void {
    this.observers.push(observer);
  }

  public unobserve(observer: Observer<this>): void {
    this.observers = this.observers.filter((obs) => obs !== observer);
  }

  public notify(isDelete = false): void {
    this.observers.forEach((observer) => observer(isDelete ? null : this));
  }

  public hide(): void {
    this.isVisible = false;
    this.deselect();
  }

  public show(): void {
    this.isVisible = true;
  }

  getDetailsPane(): DetailsElement[] | undefined {
    return (this as any)?._detailsPane;
  }

  static version: string = '1.0.0';

  public serialize(reference: boolean = false): unknown {
    if (reference) {
      return { $ref: this.id };
    }
    const data: { [key: string]: any } = { type: this.type, version: (this.constructor as typeof EntityBase).version };
    // EntityBase.references.forEach((ref) => {
    //   if (this[ref]) data[ref] = this[ref].serialize(true);
    // });

    const excludedProperties = (this.constructor as typeof EntityBase).excludedProperties;
    if (!excludedProperties.has('parent')) {
      data['parent'] = this.parent?.serialize(true);
    } else {
      if (data['parent']) {
        data.delete('parent');
      }
    }
    data['labels'] = this.labels?.map((label) => label?.serialize(true)) ?? [];
    data['children'] = this.children?.map((child) => child?.serialize(true)) ?? [];
    Object.keys(this).forEach((key) => {
      if (excludedProperties.has(key)) return;
      const value = this[key];
      if (typeof value !== 'function' && typeof value !== 'object') {
        data[key] = value;
      } else if (key === 'position') {
        data[key] = { ...value };
      } else if (key === 'attributes') {
        data[key] = { ...value };
      }
      // TODO any reason why we're ignoring objects? they could be arrays or other complex composite types
      // I'm just doing scopes for now until we know why objects are being ignored - Sam
      if (key === 'scopes') data[key] = value;
    });
    return data;
  }

  static setFindFunction(findEntity: (id: string) => EntityBase) {
    EntityBase.findEntity = findEntity;
  }

  public recalculateDimensions(isDevMode: boolean) {
    // Do nothing in base class
  }

  protected ingestThread(thread: Thread) {
    thread.parent = this;
  }

  protected ejectThread(thread: Thread) {
    thread.parent = undefined;
  }

  public get boundingBox() {
    return {
      x: this.position.x,
      y: this.position.y,
      width: this.width,
      height: this.height,
    } as const;
  }
}
