import { IStore } from '../../data/Store';
import {
  CategoryToolbarCompactItemElement,
  CategoryToolbarElement,
  CategoryToolbarItemElement,
  Layout,
  RowLayout,
  SwitchToolbarElement,
  TabsToolbarElement,
  TabToolbarElement,
  ToolbarElement,
  ToolbarIconButtonElement,
  ToolbarLayout,
} from './Toolbar.types';
import { TOOLBAR_DEFAULT_STATE, ToolbarState } from './ToolbarState';
import { layout } from './ToolbarItems';
import { EntityParserFactory } from '../../entities/EntityParserFactory';
import { EntityType } from '../../entities/EntityType';
import { EntityBase } from '../../entities/EntityBase';
import { ConstructBase } from '../../entities/constructs/ConstructBase';
import { AssetBase } from '../../entities/assets/AssetBase';
import { Scheme } from 'narrative-studio-sdk';
import { SchemeName } from '../../commands/apps/SetSchemeCommand';

export type AbstractConstructor<T = object> = abstract new (...args: any[]) => T;

export class Toolbar {
  constructor(private readonly store: IStore) {
    this.originalLayout = JSON.parse(JSON.stringify(layout));
    this.store.getState().setToolbar({
      ...TOOLBAR_DEFAULT_STATE,
      isOpen: false,
      compactMode: false,
      layout: {
        header: this.originalLayout.header,
        body: {
          rows: [],
        },
      },
      selectedEntity: '',
      selectedSchemes: [],
      currentFilter: '',
    } satisfies ToolbarState);
  }

  private originalLayout: ToolbarLayout;

  public reset(): void {
    this.store.getState().setToolbar({
      ...this.store.getState().toolbar,
      currentFilter: '',
      isOpen: false,
      layout: this.originalLayout,
    } satisfies ToolbarState);
  }

  public close(): void {
    const currentState = this.store.getState();
    const header = currentState.toolbar.layout.header;
    this.resetSelectorsActiveState(header);
    this.store.getState().setToolbar({
      ...this.store.getState().toolbar,
      isOpen: false,
      compactMode: false,
      layout: {
        header: header,
        body: {
          rows: [],
        },
      },
      selectedEntity: '',
      selectedEntityIconUrl: '',
      currentFilter: '',
    } satisfies ToolbarState);
  }

  public setCompactMode(isCompactMode: boolean): void {
    const currentFilter = this.store.getState().toolbar.currentFilter;
    const updatedLayout = currentFilter ? this.getFilteredLayout(currentFilter) : this.getLayout();
    if (isCompactMode) {
      this.transformLayoutToCompactMode(updatedLayout);
    }
    this.store.getState().setToolbar({
      ...this.store.getState().toolbar,
      compactMode: isCompactMode,
      layout: updatedLayout,
    } satisfies ToolbarState);
  }

  public search(searchString: string): void {
    const currentFilter = this.store.getState().toolbar.currentFilter;
    const updatedLayout = currentFilter ? this.getFilteredLayout(currentFilter) : this.getLayout();
    if (!searchString) {
      this.store.getState().setToolbar({
        ...this.store.getState().toolbar,
        layout: updatedLayout,
      } satisfies ToolbarState);
      return;
    }
    updatedLayout.body.rows = updatedLayout.body.rows.map((row) => ({
      ...row,
      elements: row.elements.map((element) =>
        element.type === 'textbox' ? { ...element, value: searchString || '' } : element,
      ),
    }));
    const lowerCaseSearch = searchString.toLowerCase();
    const filteredRows = updatedLayout.body.rows.map((row) => ({
      ...row,
      elements: row.elements
        .map((element: any) => {
          if (element.type === 'category') {
            const filteredItems = element.elements.filter((item: CategoryToolbarElement) =>
              item.label.toLowerCase().startsWith(lowerCaseSearch),
            );
            if (filteredItems.length > 0) {
              return { ...element, elements: filteredItems };
            }
            return null;
          }
          return element;
        })
        .filter(Boolean),
    }));
    this.store.getState().setToolbar({
      ...this.store.getState().toolbar,
      layout: {
        ...updatedLayout,
        body: {
          ...updatedLayout.body,
          rows: filteredRows,
        },
      },
    } satisfies ToolbarState);
  }

  public changeScheme(scheme: string): void {
    let updatedLayout = this.getLayout();
    const filter = this.store.getState().toolbar.currentFilter as 'Construct' | 'Asset';
    if (filter) {
      updatedLayout = this.getFilteredLayout(filter);
    }
    this.transformLayoutForScheme(updatedLayout, scheme);
    const selectedSchemes = this.store.getState().toolbar.selectedSchemes;
    const filterTypeIndex = selectedSchemes.findIndex(
      (selectedScheme: { filter: string }) => selectedScheme.filter === filter,
    );
    if (filterTypeIndex >= 0) {
      selectedSchemes[filterTypeIndex] = { filter, scheme };
    } else {
      selectedSchemes.push({ filter, scheme });
    }
    this.store.getState().setToolbar({
      ...this.store.getState().toolbar,
      layout: updatedLayout,
      selectedSchemes,
    } satisfies ToolbarState);
  }

  public selectEntity(entityType: string): void {
    if (entityType === 'upload') {
      this.store.getState().setShowUploadLinkModal(true);
      return;
    }
    const toolbarState = this.store.getState().toolbar as ToolbarState;
    if (entityType === EntityType.Thread) {
      this.store.getState().setToolbar({
        ...toolbarState,
        selectedEntity: entityType,
        selectedEntityIconUrl: '/comment.svg',
      } satisfies ToolbarState);
      return;
    }
    const isOpen = toolbarState.isOpen;
    if (!entityType || !isOpen) {
      const categoryItem = this.findCategoryItem(entityType, this.originalLayout, toolbarState.compactMode);
      this.setToolbarSelection(entityType, categoryItem?.icon || '');
      return;
    }
    const isConstruct = EntityParserFactory.isInstanceOfBase(entityType, ConstructBase);
    const isAsset = EntityParserFactory.isInstanceOfBase(entityType, AssetBase);
    if (!isConstruct && !isAsset) {
      return;
    }
    const updatedLayout = toolbarState.layout;
    let categoryItem = this.findCategoryItem(entityType, updatedLayout, toolbarState.compactMode);
    if (categoryItem) {
      updatedLayout.header = this.updateHeaderWithSelection(
        updatedLayout.header,
        isConstruct,
        isAsset,
        categoryItem,
        entityType,
      );
    } else {
      categoryItem = this.findCategoryItem(entityType, this.originalLayout, toolbarState.compactMode);
    }
    this.store.getState().setToolbar({
      ...toolbarState,
      selectedEntity: entityType,
      layout: updatedLayout,
      selectedEntityIconUrl: categoryItem?.icon || '',
    } satisfies ToolbarState);
  }

  public getSelectedEntityType(): string {
    return this.store.getState().toolbar.selectedEntity;
  }

  public setFilter(filterType: string): void {
    const toolbarState = this.store.getState().toolbar as ToolbarState;
    const updatedLayout = this.getFilteredLayout(filterType);
    if (toolbarState.currentFilter === filterType) {
      this.close();
      return;
    }
    if (toolbarState.compactMode) {
      this.transformLayoutToCompactMode(updatedLayout);
    }
    if (toolbarState.selectedSchemes.length > 0) {
      const selectedScheme = toolbarState.selectedSchemes.find((scheme) => scheme.filter === filterType);
      this.transformLayoutForScheme(updatedLayout, selectedScheme?.scheme || '');
    }
    this.store.getState().setToolbar({
      ...toolbarState,
      layout: updatedLayout,
      currentFilter: filterType,
      isOpen: true,
    } satisfies ToolbarState);
  }

  public showResolvedComments(show: boolean): void {
    const updatedLayout = JSON.parse(JSON.stringify(this.store.getState().toolbar.layout));
    updatedLayout.header.rows = updatedLayout.header.rows.map((row) => ({
      ...row,
      elements: row.elements.map((element) => {
        if (element.type === 'icon-button' && element.more?.elements) {
          element.more.elements = element.more.elements.map((nestedElement) => {
            if (nestedElement.type === 'switch' && nestedElement.name === 'showResolvedComments') {
              return {
                ...nestedElement,
                value: show,
                onChange: {
                  ...nestedElement.onChange,
                  params: {
                    ...nestedElement.onChange.params,
                    value: !show,
                  },
                },
              };
            }
            return nestedElement;
          });
        }
        return element;
      }),
    }));
    this.store.getState().setToolbar({
      ...this.store.getState().toolbar,
      layout: updatedLayout,
    } satisfies ToolbarState);
  }

  private resetSelectorsActiveState(header: Layout) {
    header.rows.forEach((row: RowLayout) => {
      row.elements = row.elements.map((element: ToolbarIconButtonElement) => {
        if (element.name === 'constructSelector' || element.name === 'assetSelector') {
          return {
            ...element,
            more: element.more
              ? {
                  ...element.more,
                  active: false,
                }
              : undefined,
          };
        }
        return element;
      });
    });
  }

  private setToolbarSelection(entityType: string, icon: string): void {
    this.store.getState().setToolbar({
      ...this.store.getState().toolbar,
      selectedEntity: entityType,
      selectedEntityIconUrl: icon,
    } satisfies ToolbarState);
  }

  private findCategoryItem(
    entityType: string,
    layout: ToolbarLayout,
    isCompactMode: boolean,
  ): CategoryToolbarItemElement | CategoryToolbarCompactItemElement | undefined {
    let categoryItem: CategoryToolbarItemElement | CategoryToolbarCompactItemElement | undefined;
    if (isCompactMode) {
      layout.body.rows.forEach((row) => {
        row.elements.forEach((element: CategoryToolbarCompactItemElement) => {
          if (element.type === 'category-compact' && element.value === entityType) {
            categoryItem = element;
          }
        });
      });
    } else {
      layout.body.rows.forEach((row) => {
        row.elements.forEach((element) => {
          if (element.type === 'category' && 'elements' in element) {
            categoryItem =
              categoryItem ||
              element.elements.find((item) => item.type === 'category-item' && item.value === entityType);
          }
        });
      });
    }
    return categoryItem;
  }

  private updateHeaderWithSelection(
    layoutHeader: Layout,
    isConstruct: boolean,
    isAsset: boolean,
    categoryItem: CategoryToolbarItemElement | CategoryToolbarCompactItemElement,
    entityType: string,
  ): Layout {
    layoutHeader.rows.forEach((row) => {
      row.elements = row.elements.map((element: ToolbarIconButtonElement) => {
        if (isConstruct && element.name === 'constructSelector') {
          return this.updateIconButtonElement(element, categoryItem, entityType);
        }
        if (isAsset && element.name === 'assetSelector') {
          return this.updateIconButtonElement(element, categoryItem, entityType);
        }
        return element;
      });
    });
    return layoutHeader;
  }

  private updateIconButtonElement(
    element: ToolbarIconButtonElement,
    categoryItem: CategoryToolbarItemElement | CategoryToolbarCompactItemElement,
    entityType: string,
  ): ToolbarIconButtonElement {
    return {
      ...element,
      iconColor: categoryItem?.iconColor ?? '',
      icon: categoryItem?.icon ?? ('shape' in categoryItem ? categoryItem.shape : 'square') ?? 'square',
      iconBorder: categoryItem?.iconBorder,
      tooltip: categoryItem?.label ?? '',
      onClick: {
        ...element.onClick,
        params: {
          ...element.onClick.params,
          value: entityType,
        },
      },
      onDragStart: element.onDragStart
        ? {
            ...element.onDragStart,
            ...(element.onDragStart.command && { command: element.onDragStart.command }),
            params: {
              ...element.onDragStart.params,
              value: entityType,
            },
          }
        : undefined,
    };
  }

  private getFilteredLayout(filterType: string): ToolbarLayout {
    const updatedLayout = this.getLayout();
    const baseClass = this.getBaseClass(filterType);
    updatedLayout.body.rows = updatedLayout.body.rows.map((row) => ({
      ...row,
      elements: row.elements.flatMap((element: ToolbarElement) => {
        if (element.type === 'category' && 'elements' in element) {
          const filteredItems = baseClass
            ? element.elements.filter((item) => {
                return (
                  item.type === 'category-item' &&
                  EntityParserFactory.isInstanceOfBase(item.value as EntityType, baseClass)
                );
              })
            : [];
          return filteredItems.length > 0 ? { ...element, elements: filteredItems } : [];
        }
        return element;
      }),
    }));
    updatedLayout.header.rows = this.setActiveStateForSelectorRows(updatedLayout, baseClass);
    updatedLayout.body.rows = this.filterRowsForTabsWithItems(updatedLayout);
    return updatedLayout;
  }

  private getBaseClass(filterType: string): AbstractConstructor<EntityBase> | null {
    if (filterType === 'Asset') {
      return AssetBase;
    }
    if (filterType === 'Construct') {
      return ConstructBase;
    }
    return null;
  }

  private transformLayoutForScheme(updatedLayout: ToolbarLayout, scheme: string) {
    if (!scheme) {
      scheme = 'all';
    }
    updatedLayout.body.rows = updatedLayout.body.rows.map((row: { elements: ToolbarElement[] }) => ({
      ...row,
      elements: row.elements.map((element: ToolbarElement) => {
        if (element.type === 'tabs') {
          return {
            ...element,
            value: scheme,
            scheme,
          };
        }
        return element;
      }),
    }));
    updatedLayout.body.rows = updatedLayout.body.rows.flatMap((row: { elements: ToolbarElement[] }) => ({
      ...row,
      elements: row.elements
        .map((element: ToolbarElement) => {
          if (element.type === 'category') {
            if (scheme === 'all' || scheme === '') {
              return element;
            }
            const filteredItems = element.elements.filter((item: { scheme: string }) => item.scheme === scheme);

            return filteredItems.length > 0 ? { ...element, elements: filteredItems } : null;
          }
          return element;
        })
        .filter((element): element is ToolbarElement => element !== null),
    }));
  }

  private filterRowsForTabsWithItems(layout: ToolbarLayout): RowLayout[] {
    return layout.body.rows.map((row) => ({
      ...row,
      elements: row.elements.flatMap((element, index, elementsArray): ToolbarElement[] => {
        if (element.type === 'tabs') {
          const validTabs = element.tabs.filter((tab) => {
            if (!tab.scheme || tab.scheme === '') {
              return true;
            }
            return layout.body.rows.some((bodyRow) =>
              bodyRow.elements.some(
                (categoryElement) =>
                  categoryElement.type === 'category' &&
                  categoryElement.elements.some((item) => item.type === 'category-item' && item.scheme === tab.scheme),
              ),
            );
          });

          if (validTabs.length === 2 && validTabs[0].value === 'all') {
            if (elementsArray[index + 1]?.type === 'divider') {
              elementsArray.splice(index + 1, 1);
            }
            return [];
          }
          return validTabs.length > 0 ? [{ ...element, tabs: validTabs }] : [];
        }
        if (
          element.type === 'divider' &&
          index > 0 &&
          elementsArray[index - 1]?.type === 'tabs' &&
          !(elementsArray[index - 1] as TabsToolbarElement).tabs.length
        ) {
          return [];
        }

        return [element];
      }),
    }));
  }

  private setActiveStateForSelectorRows(
    updatedLayout: ToolbarLayout,
    baseClass: AbstractConstructor<EntityBase> | null,
  ): RowLayout[] {
    return updatedLayout.header.rows.map((row) => ({
      ...row,
      elements: row.elements.map((element: any) => {
        if (baseClass === ConstructBase && element.name === 'constructSelector') {
          return {
            ...element,
            more: {
              ...element.more,
              active: true,
            },
          };
        }
        if (baseClass === AssetBase && element.name === 'assetSelector') {
          return {
            ...element,
            more: {
              ...element.more,
              active: true,
            },
          };
        }
        if (
          (baseClass === ConstructBase && element.name === 'assetSelector') ||
          (baseClass === AssetBase && element.name === 'constructSelector')
        ) {
          return { ...element, more: { ...element.more, active: false } };
        }
        return element;
      }),
    }));
  }

  private transformLayoutToCompactMode(updatedLayout) {
    updatedLayout.body.rows = [
      {
        type: 'list',
        grow: 1,
        elements: [
          ...updatedLayout.body.rows[0].elements
            .filter((element) => element.type === 'switch' && element.name === 'compactMode')
            .map((element: SwitchToolbarElement) => ({
              ...element,
              value: true,
              onChange: {
                ...element.onChange,
                params: {
                  ...element.onChange.params,
                  value: true,
                },
              },
            })),
          ...updatedLayout.body.rows
            .flatMap((row) => row.elements.filter((element) => element.type === 'category'))
            .flatMap((category) =>
              category.elements.map((item) => ({
                ...item,
                type: 'category-compact',
              })),
            ),
        ],
      },
    ];
  }

  private getLayout(): ToolbarLayout {
    const originalLayout = JSON.parse(JSON.stringify(this.originalLayout));
    const toolbar = this.store.getState().toolbar as ToolbarState;
    originalLayout.compactMode = toolbar.compactMode;
    originalLayout.isOpen = toolbar.isOpen;
    originalLayout.selectedEntity = toolbar.selectedEntity;
    originalLayout.header = toolbar.layout.header;
    return originalLayout;
  }

  addSchemes(schemes: Scheme[]): void {
    const updatedLayout = JSON.parse(JSON.stringify(this.originalLayout));
    schemes.forEach((scheme) => {
      scheme.categories.forEach((category) => {
        // Check if the scheme already has a tab in the body
        const schemeValue = scheme.name.replace(' ', '-').toLowerCase();
        let existingTab = updatedLayout.body.rows[0].elements.find(
          (element: TabsToolbarElement) =>
            element.type === 'tabs' && element.tabs.some((tab: TabToolbarElement) => tab.scheme === schemeValue),
        );
        if (!existingTab) {
          // add a new tab for the scheme if it doesn't exist
          existingTab = updatedLayout.body.rows[0].elements.find(
            (element: TabsToolbarElement) => element.type === 'tabs',
          );
          if (existingTab) {
            existingTab.tabs.push({
              type: 'tab',
              label: scheme.name,
              value: schemeValue,
              scheme: schemeValue,
              onChange: {
                command: 'SetToolbarStateCommand',
                params: {
                  key: 'scheme',
                  value: schemeValue,
                },
              },
            });
          }
        }
        // add category from scheme if it doesn't already exist
        let existingCategory = updatedLayout.body.rows[1].elements.find(
          (element: CategoryToolbarElement) => element.type === 'category' && element.label === category.name,
        );
        if (!existingCategory) {
          existingCategory = {
            type: 'category',
            label: category.name,
            elements: [],
          };
          updatedLayout.body.rows[1].elements.push(existingCategory);
        }
        // add constructs and assets to the category if they don't already exist
        const categoryItems = existingCategory.elements;
        (category.constructs || []).forEach((construct) => {
          if (!categoryItems.some((item: CategoryToolbarItemElement) => item.value === construct.type)) {
            categoryItems.push({
              type: 'category-item',
              label: construct.label,
              value: construct.type,
              iconColor: construct?.style?.backgroundColor,
              iconBorder: construct.style?.borderColor
                ? `${construct.style?.borderWidth ?? 1}px  solid ${construct.style.borderColor}`
                : undefined,
              icon: construct.icon,
              shape: construct.shape,
              description: construct.description,
              scheme: schemeValue,
              onClick: {
                command: 'SetToolbarStateCommand',
                params: {
                  key: 'selectedEntity',
                  value: construct.type,
                },
              },
              onDragStart: {
                command: 'SetToolbarStateCommand',
                params: {
                  key: 'selectedEntity',
                  value: construct.type,
                },
              },
              onDragEnd: {
                command: 'SetToolbarStateCommand',
                params: {
                  key: 'selectedEntity',
                  value: construct.type,
                },
              },
            });
          }
        });
        (category.assets || []).forEach((asset) => {
          if (!categoryItems.some((item: CategoryToolbarItemElement) => item.value === asset.type)) {
            categoryItems.push({
              type: 'category-item',
              label: asset.label,
              value: asset.type,
              icon: asset.icon,
              description: asset.description,
              scheme: schemeValue,
              onClick: {
                command: 'SetToolbarStateCommand',
                params: {
                  key: 'selectedEntity',
                  value: asset.type,
                },
              },
              onDragStart: {
                command: 'SetToolbarStateCommand',
                params: {
                  key: 'selectedEntity',
                  value: asset.type,
                },
              },
              onDragEnd: {
                command: 'SetToolbarStateCommand',
                params: {
                  key: 'selectedEntity',
                  value: asset.type,
                },
              },
            });
          }
        });
      });
    });
    this.originalLayout = updatedLayout;
    this.reset();
    this.close();
  }

  removeSchemes(schemes: Scheme[]): void {
    const updatedLayout = JSON.parse(JSON.stringify(this.originalLayout));
    schemes.forEach((scheme) => {
      const tabsElement = updatedLayout.body.rows[0].elements.find(
        (element: TabsToolbarElement) => element.type === 'tabs',
      );
      const schemeValue = scheme.name.replace(' ', '-').toLowerCase();
      if (tabsElement) {
        tabsElement.tabs = tabsElement.tabs.filter((tab: TabToolbarElement) => tab.value !== schemeValue);
      }
      scheme.categories.forEach((category) => {
        const existingCategoryIndex = updatedLayout.body.rows[1].elements.findIndex(
          (element: CategoryToolbarElement) => element.type === 'category' && element.label === category.name,
        );
        if (existingCategoryIndex >= 0) {
          const existingCategory = updatedLayout.body.rows[1].elements[existingCategoryIndex];
          existingCategory.elements = existingCategory.elements.filter(
            (item: { scheme: string }) => item.scheme !== schemeValue,
          );
          if (existingCategory.elements.length === 0) {
            updatedLayout.body.rows[1].elements.splice(existingCategoryIndex, 1);
          }
        }
      });
    });
    this.originalLayout = updatedLayout;
    this.reset();
  }

  public setDefaultSelectedConstruct(scheme: SchemeName): void {
    const origin = typeof window === 'object' ? window.location.origin : '';

    const updatedLayout = JSON.parse(JSON.stringify(this.store.getState().toolbar.layout));
    updatedLayout.header.rows = updatedLayout.header.rows.map((row) => ({
      ...row,
      elements: row.elements.map((element) => {
        if (element.name === 'constructSelector') {
          const isEventModeling = scheme === 'EVENT_MODELING';
          const entityType = isEventModeling ? 'EventModel' : 'Narrative';
          const tooltipText = entityType.replace(/([A-Z])/g, ' $1').trim();
          const commandParams = {
            command: 'SetToolbarStateCommand',
            params: { key: 'selectedEntity', value: entityType },
          };

          return {
            ...element,
            tooltip: tooltipText,
            icon: `${origin}/${isEventModeling ? 'eventmodel' : 'narrative'}.svg`,
            onClick: commandParams,
            onDragStart: commandParams,
          };
        }
        return element;
      }),
    }));
    this.store.getState().setToolbar({
      ...this.store.getState().toolbar,
      layout: updatedLayout,
    } satisfies ToolbarState);
  }

  clearSchemes(): void {
    this.originalLayout = JSON.parse(JSON.stringify(layout));
    this.reset();
  }
}
