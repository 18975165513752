import {
  Background,
  Connection,
  ConnectionMode,
  EdgeChange,
  FitViewOptions,
  Node,
  NodeChange,
  NodeMouseHandler,
  OnConnect,
  OnMoveStart,
  OnNodeDrag,
  OnReconnect,
  Panel,
  PanOnScrollMode,
  ReactFlow,
  ReactFlowProps,
  ReactFlowProvider,
  SelectionMode,
  SnapGrid,
  useOnSelectionChange,
  UseOnSelectionChangeOptions,
  useOnViewportChange,
  useReactFlow,
  useStoreApi,
  Viewport,
  XYPosition,
} from '@xyflow/react';
import { FpsView } from 'react-fps';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { Box, ClickAwayListener, Stack } from '@mui/material';
import {
  DragEvent,
  DragEventHandler,
  FC,
  MouseEvent as ReactMouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Cursors } from './cursors';
import { useHotkeys, useWindowEvent } from '@mantine/hooks';
import {
  CreateUploadCommand,
  DEBUG_CONFIG,
  DisconnectFileCommand,
  EntityType,
  FileType,
  InteractorResponse,
  LoadFileCommand,
  Status,
  UploadType,
} from '@xspecs/single-source-model';
import { CanvasControls } from './controls/canvas-controls';
import { useAuth } from '../../../auth';
import { useLazyQuery } from '@apollo/client';
import { useSnackStack } from '../../../wrappers/snack-stack-context';
import { ManageLabelsModal } from '../../labels/manage-labels-modal/manage-labels-modal';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { LabelSaveParams } from '../../labels/labels-list/labels-list-item/labels-list-item';
import { RestorePreviousVersionModal } from '../restore-previous-version-modal/restore-previous-version-modal';
import { UploadLinkModal } from '../upload-link-modal/upload-link-modal';
import { sid } from '@xspecs/short-id';
import { useCanvasCenter } from '../../../hooks/use-canvas-center';
import { useIntl } from 'react-intl';
import { edgeTypes, nodeTypes } from './single-source-model-canvas.node-types';
import { logger } from '@xspecs/logger';
import { Annotator } from './annotator/annotator';
import { useImageDimensions } from './hooks/use-image-dimensions';
import { resizeImage } from '../../../utils/resizeImage';
import { WorkspaceResetStateModal } from '../../workspace-reset-state-modal/workspace-reset-state-modal';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { FILE_UPLOAD_URLS_QUERY, LINK_PREVIEW_DETAILS_QUERY } from '../../../graphql/queries';
import { extractFigmaFileName, isValidFigmaEmbedUrl } from '../utils';
import { AppsView } from './apps-view';
import { CanvasToolbar } from '../../canvas-toolbar/canvas-toolbar';
import { SingleSourceModelToolbarAvatars } from '../toolbar/avatars/single-source-model-toolbar-avatars';
import { NavMenuLabels } from '../../nav-menu/labels/nav-menu-labels';
import { Button, useCommandDispatch } from '@xspecs/design-system';
import { isAutoUser } from '../../../lib/utils';
import { FollowingUserOverlay } from './following-user-overlay';
import { BotMessageSquare } from 'lucide-react';
import { UrlPreviewModal } from '../url-preview-modal/url-preview-modal';

const fitViewOptions: FitViewOptions = { padding: 0.5, maxZoom: 1, duration: 600 };

let viewportsBuffer: Viewport[] = [];

type SingleSourceModelCanvasProps = {
  id: string;
  fileType: string;
};
const _SingleSourceModelCanvas: FC<SingleSourceModelCanvasProps> = (props) => {
  const { id, fileType } = props;

  const { workspace } = useActiveWorkspace();
  const setViewports = useSingleSourceStore.use.setViewports();
  const viewports = useSingleSourceStore.use.viewports();
  const { dispatchCommand } = useCommandDispatch();
  const followed = useSingleSourceStore.use.followedUser();
  const setShowChatForModel = useSingleSourceStore.use.setShowChatForModel();

  const { screenToFlowPosition, fitView, zoomIn, zoomOut, zoomTo, setViewport, getViewport } = useReactFlow();

  const { application } = useApplication();
  const { user } = useAuth();
  const { nodes, edges } = useSingleSourceStore.use.graph();
  if (DEBUG_CONFIG.nodesAndEdges) logger.log('useSingleSourceGraph - nodes and edges', nodes, edges);
  const isFittedForWorkspace = useRef<string>();
  const getCanvasCenter = useCanvasCenter();
  const [getLinkPreview] = useLazyQuery(LINK_PREVIEW_DETAILS_QUERY, { fetchPolicy: 'no-cache' });
  const [getPreSignedUrl] = useLazyQuery(FILE_UPLOAD_URLS_QUERY, { fetchPolicy: 'no-cache' });
  const { formatMessage: f } = useIntl();
  const store = useStoreApi();
  const { getImageDimensions } = useImageDimensions();
  const { addToast, removeToast } = useSnackStack();
  const mouseInputMode = useSingleSourceStore.use.mouseInputMode();

  useOnViewportChange({
    onEnd: (viewport: Viewport) => {
      if (!viewport || !workspace) return;
      setViewports(workspace.id, viewport);
      application?.getModelContext()?.interactor.updateViewport(viewport);
    },
    onChange: (viewport: Viewport) => {
      application?.getModelContext()?.interactor.updateViewport(viewport);
    },
  });

  useEffect(() => {
    if (!followed?.viewport) return;
    if ('x' in followed.viewport) {
      viewportsBuffer.push(followed.viewport);
      if (viewportsBuffer.length > 5) {
        viewportsBuffer = [];
        setViewport(followed.viewport, { duration: 100 });
      }
    }
  }, [followed, setViewport]);

  useEffect(() => {
    if (!followed?.viewport) return;
    if (followed && 'nodes' in followed.viewport) {
      const nodes = followed.viewport.nodes ?? [];
      if (nodes.length === 0) return;
      fitView(followed.viewport);
    }
  }, [fitView, followed]);

  const options: UseOnSelectionChangeOptions = useMemo(
    () => ({
      onChange: (params) => {
        if (params.nodes.length > 1) store.setState({ nodesSelectionActive: true });
      },
    }),
    [store],
  );
  useOnSelectionChange(options);

  const showUploadLinkModal = useSingleSourceStore.use.showUploadLinkModal();
  const constructToPanTo = useSingleSourceStore.use.constructToPanTo();
  const setConstructToPanTo = useSingleSourceStore.use.setConstructToPanTo();
  const _isLoaded = useSingleSourceStore.use.isLoaded();
  const file = id ? useSingleSourceStore.use.filesById()[id] : undefined;
  const showManageLabelsModal = useSingleSourceStore.use.showManageLabelsModal();
  const setShowManageLabelsModal = useSingleSourceStore.use.setShowManageLabelsModal();
  const labels = useSingleSourceStore.use.labels();
  const setShowUploadLinkModal = useSingleSourceStore.use.setShowUploadLinkModal();
  const setShowWorkspaceResetModal = useSingleSourceStore.use.setShowWorkspaceResetModal();
  const showWorkspaceResetModal = useSingleSourceStore.use.showWorkspaceResetModal();

  const [isModPressed, setIsModPressed] = useState(false);
  const focusedEntity = useRef('');

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey || event.ctrlKey) {
        setIsModPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.metaKey && !event.ctrlKey) {
        setIsModPressed(false);
      }
    };

    const handleWindowFocus = () => {
      setIsModPressed(false); // Reset on window focus
    };

    const handleWindowBlur = () => {
      setIsModPressed(false); // Reset on window blur
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, []);

  // TODO Osama fix this please. I made a hack because it's failing on workspace switch
  // if (!file) window.location.reload();
  const isLoaded = _isLoaded && file && file.status === Status.Synced;

  const respondTo = useCallback(
    (interactorResponse: InteractorResponse) => {
      if (!interactorResponse) return;
      const { action, params } = interactorResponse;
      switch (action) {
        case 'fitView':
          void fitView(params);
          break;
        case 'snackbar':
          addToast(params);
          break;
        case 'snackbars':
          if (Array.isArray(params)) params.forEach((toast) => addToast(toast));
          break;
        case 'uploadFiles':
          return params;
      }
    },
    [addToast, fitView],
  );

  const handleFileDrop = useCallback(
    async (position: XYPosition, event: DragEvent) => {
      const file = event.dataTransfer.files[0];

      if (!file) return;

      const fileType = file.type.startsWith('image') ? UploadType.Image : UploadType.File;
      const assetId = sid();

      try {
        addToast({ key: assetId, message: f({ id: 'uploading-file' }), duration: 0 });
        const { data } = await getPreSignedUrl({ variables: { parentId: assetId } });

        if (!data?.signedMediaUploadUrl) {
          addToast({
            message: f({ id: 'failed-to-get-signed-media-upload-url' }),
            severity: 'error',
          });
          return;
        }
        const uploadFileResult = await fetch(data.signedMediaUploadUrl.putUrl, {
          method: 'PUT',
          headers: { 'Content-Type': file.type || 'application/octet-stream' },
          body: file,
        });
        if (!uploadFileResult.ok) {
          addToast({
            message: f({ id: 'failed-to-upload-file' }),
            severity: 'error',
          });
          return;
        }

        let imgDimensions;
        if (fileType === UploadType.Image) {
          let originalDimensions;

          try {
            originalDimensions = await getImageDimensions(file);
          } catch (error) {
            originalDimensions = {};
          } finally {
            imgDimensions = resizeImage(originalDimensions);
          }
        }
        application
          ?.getModelContext()
          ?.interactor?.onDropFiles(position, file, data.signedMediaUploadUrl.getUrl, assetId, imgDimensions);
      } finally {
        removeToast(assetId);
      }
    },
    [addToast, application, f, getImageDimensions, getPreSignedUrl, removeToast],
  );

  const onDrop = useCallback<DragEventHandler>(
    async (event) => {
      event.preventDefault();
      const position = screenToFlowPosition({ x: event.clientX, y: event.clientY });
      if (event.dataTransfer.files.length > 0) {
        return await handleFileDrop(position, event);
      }
      const typeData = event.dataTransfer.getData('application/reactflow');
      application?.getModelContext()?.interactor?.onDrop(position, typeData, user.sub);
    },
    [screenToFlowPosition, application, user.sub, handleFileDrop],
  );

  const onMouseUp = useCallback(
    (event: ReactMouseEvent) => {
      return application
        ?.getModelContext()
        ?.interactor?.onMouseUp(screenToFlowPosition({ x: event.clientX, y: event.clientY }), user.sub);
    },
    [application, screenToFlowPosition, user.sub],
  );

  const onNodesChange = useCallback(
    (nodeChanges: NodeChange[]) => application?.getModelContext()?.interactor?.onNodeChanges(nodeChanges),
    [application],
  );

  const onNodeClick = useCallback<NodeMouseHandler>(
    (event, node) =>
      application
        ?.getModelContext()
        ?.interactor?.onNodeClick(
          { x: event.clientX, y: event.clientY },
          screenToFlowPosition({ x: event.clientX, y: event.clientY }),
          node.id,
          user.sub,
        ),
    [application, screenToFlowPosition, user.sub],
  );

  const handleDragEvent = useCallback(
    (
      handler: (coords: XYPosition, flowCoords: XYPosition, nodes: Node[], params: { isModPressed: boolean }) => void,
      event: ReactMouseEvent,
      nodes: Node[],
      params: { isModPressed: boolean },
    ) => {
      const coords = { x: event.clientX, y: event.clientY };
      handler.bind(application?.getModelContext()?.interactor)(coords, screenToFlowPosition(coords), nodes, params);
    },
    [application, screenToFlowPosition],
  );

  const onNodeDragStart = useCallback<OnNodeDrag>(
    (event, node: Node, nodes: Node[]) => {
      application
        ?.getModelContext()
        ?.interactor?.onNodeDragStart(
          { x: event.clientX, y: event.clientY },
          screenToFlowPosition({ x: event.clientX, y: event.clientY }),
          nodes,
        );
    },
    [application, screenToFlowPosition],
  );

  const onNodeDrag = useCallback<OnNodeDrag>(
    (e, node, nodes) => {
      const onNodeDrag = application?.getModelContext()?.interactor?.onNodeDrag;
      if (!onNodeDrag) return;
      handleDragEvent(onNodeDrag, e, nodes, { isModPressed });
    },
    [application, handleDragEvent, isModPressed],
  );

  const onNodeDragStop = useCallback<OnNodeDrag>(
    (e, node, nodes) => {
      const onNodeDragStop = application?.getModelContext()?.interactor?.onNodeDragStop;
      if (!onNodeDragStop) return;
      handleDragEvent(onNodeDragStop, e, nodes, { isModPressed });
    },
    [application, handleDragEvent, isModPressed],
  );

  const onMouseMove = useCallback<MouseEventHandler>(
    (e) => {
      const onMouseMove = application?.getModelContext()?.interactor?.onMouseMove;
      if (!onMouseMove) return;
      handleDragEvent(onMouseMove, e, [], { isModPressed });
    },
    [application, handleDragEvent, isModPressed],
  );

  // useWindowEvent('dragover', (e) => {
  //   if (!application?.model?.interactor?.onDragOver) return;
  //   handleDragEvent(application?.model?.interactor?.onDragOver, e as unknown as ReactMouseEvent, [], { isModPressed });
  // });

  const onMouseLeave = useCallback(() => application?.getModelContext()?.interactor?.onMouseLeave(), [application]);

  const onEdgesChange = useCallback(
    (edgeChanges: EdgeChange[]) => application?.getModelContext()?.interactor?.onEdgesChange(edgeChanges),
    [application],
  );

  const onConnect = useCallback<OnConnect>(
    (connection: Connection) => respondTo(application?.getModelContext()?.interactor?.onConnect(connection)),
    [application, respondTo],
  );

  const onReconnect = useCallback<OnReconnect>(
    (oldEdge, newConnection) => {
      application?.getModelContext()?.interactor?.onReconnect(oldEdge, newConnection);
    },
    [application],
  );

  const onUploadInternal = useCallback(
    async (url: string) => {
      const fileType = getFileType(url);
      if (!fileType) return;
      const assetId = sid();
      const attachmentId = sid();
      const isFigmaEmbed = isValidFigmaEmbedUrl(url);
      let data;

      if (isFigmaEmbed) {
        data = { linkPreview: { title: extractFigmaFileName(url) } };
      } else {
        const response = await getLinkPreview({ variables: { url } });
        data = response.data;
      }

      const center = getCanvasCenter();

      let dimensions;
      if (fileType === UploadType.Image) {
        let originalDimensions;

        try {
          originalDimensions = await getImageDimensions(url);
        } catch (error) {
          originalDimensions = {};
        } finally {
          dimensions = resizeImage(originalDimensions);
        }
      } else if (isFigmaEmbed) {
        dimensions = { width: 500, height: 350 };
      }

      application?.context?.messageBus.send(CreateUploadCommand, {
        assetId,
        id: attachmentId,
        name: url,
        position: { x: center.x, y: center.y },
        type: fileType,
        url,
        metadata: {
          title: data?.linkPreview.title || url,
          imageUrl: data?.linkPreview.imageUrl,
          favicon: data?.linkPreview.favicon,
          ...(dimensions ?? {}),
        },
      });
    },
    [application?.context?.messageBus, getCanvasCenter, getImageDimensions, getLinkPreview],
  );

  const onUpload = useCallback(
    async (url: string) => {
      let key = '';
      try {
        key = addToast({ message: f({ id: 'uploading-link' }), duration: 0 });
        await onUploadInternal(url);
      } catch (e) {
        addToast({ message: f({ id: 'failed-to-upload-link' }), severity: 'error' });
      } finally {
        removeToast(key);
        addToast({ message: f({ id: 'link-uploaded' }), severity: 'success' });
      }
    },
    [addToast, f, onUploadInternal, removeToast],
  );

  useEffect(() => {
    if (constructToPanTo && constructToPanTo !== focusedEntity.current) {
      focusedEntity.current = constructToPanTo;
      setTimeout(async () => {
        const entity = application?.getModelContext()?.entityRepository.get(constructToPanTo);
        if (!entity) return;
        let padding = 8;
        if (entity.type === EntityType.NarrativeScript) padding = 0.5;
        if (entity.type === EntityType.Thread) padding = 12;
        await fitView({ nodes: [{ id: constructToPanTo }], duration: 500, padding: padding });
        setConstructToPanTo(undefined);
      }, 0);
    }
  }, [application, constructToPanTo, fitView, setConstructToPanTo]);

  const toolbar = useSingleSourceStore.use.toolbar();
  const cursor = useMemo(() => {
    if (!toolbar.selectedEntityIconUrl) {
      if (toolbar.selectedEntity) return `url("/${toolbar.selectedEntity.toLowerCase()}.svg"), auto`;
      return `var(--cursor), auto`;
    }
    return `url("${toolbar.selectedEntityIconUrl}"), auto`;
  }, [toolbar.selectedEntity, toolbar.selectedEntityIconUrl]);

  const resetZoom = useCallback(async () => {
    await zoomTo(1);
  }, [zoomTo]);

  // ==================== Hotkeys ====================
  useHotkeys([
    ['mod+z', () => application?.getModelContext()?.interactor?.undo()],
    ['mod+shift+z', () => application?.getModelContext()?.interactor?.redo()],
    ['mod+x', () => application?.getModelContext()?.interactor?.onCut()],
    ['mod+c', () => application?.getModelContext()?.interactor?.onCopy()],
    ['mod+v', () => application?.getModelContext()?.interactor?.onPaste()],
    ['mod+d', () => application?.getModelContext()?.interactor?.onDuplicate()],
    ['Escape', () => application?.getModelContext()?.interactor?.onEscape()],
    ['Delete', () => application?.getModelContext()?.interactor?.onDelete()],
    ['Backspace', () => application?.getModelContext()?.interactor?.onDelete()],
    ['mod+a', () => application?.getModelContext()?.interactor?.onSelectAll()],
    ['mod+shift+a', () => application?.getModelContext()?.interactor?.onDeSelectAll()],
    ['mod+ctrl+alt+shift+t', () => application?.getModelContext()?.interactor?.onTestCode()],
    ['mod+0', () => resetZoom()],
  ]);
  useWindowEvent('keydown', async (event) => {
    const increase = event.key === '+' || event.key === '=';
    const decrease = event.key === '-' || event.key === '_';
    if (
      (event.ctrlKey || event.metaKey) &&
      (event.key === '+' || event.key === '-' || event.key === '=' || event.key === '_')
    ) {
      event.preventDefault();
      if (increase) await zoomIn();
      if (decrease) await zoomOut();
      return;
    }
    if (event.altKey) {
      if (event.code === 'Digit1' || event.code === 'NumPad1') return await fitView(fitViewOptions);
      if (event.code === 'Digit2' || event.code === 'NumPad2') {
        const selectedNodes = nodes.filter((node: Node) => node.selected);
        if (selectedNodes.length > 0) await fitView({ nodes: selectedNodes, ...fitViewOptions });
      }
    }
  });

  useEffect(() => {
    if (!workspace || !isLoaded || isFittedForWorkspace.current === workspace.id) return;
    const timeoutId = setTimeout(async () => {
      isFittedForWorkspace.current = workspace.id;
      const workspaceViewport = viewports?.[workspace.id];
      try {
        if (workspaceViewport) {
          await setViewport(
            { x: workspaceViewport.x || 0, y: workspaceViewport.y || 0, zoom: workspaceViewport.zoom || 1 },
            { duration: 600 },
          );
        } else {
          await fitView(fitViewOptions);
        }
      } catch (error) {
        logger.error('Error in fitting the view:', error);
      }
    }, 700);

    return () => clearTimeout(timeoutId);
  }, [fitView, isLoaded, setViewport, viewports, workspace]);

  const labelsListItemProps = useMemo(
    () => ({
      onSave: (params: LabelSaveParams) => {
        const modelContext = application?.getModelContext();
        params.isNew
          ? modelContext?.labelsInteractor?.createNew(params)
          : modelContext?.labelsInteractor?.update(params);
      },
      onConfirmDelete: (id: string) => application?.getModelContext()?.labelsInteractor?.delete(id),
    }),
    [application],
  );

  const onConnectStart = useCallback(
    (event, params) => application?.getModelContext()?.interactor?.onConnectStart(params),
    [application],
  );
  const onSelectionChange = useCallback(
    (selection) => application?.getModelContext()?.interactor?.onSelectionChange(selection),
    [application],
  );
  const onSelectionEnd = useCallback(
    (event: ReactMouseEvent) => application?.getModelContext()?.interactor?.onSelectionEnd(event),
    [application],
  );
  const onSelectionStart = useCallback(
    (event: ReactMouseEvent) => application?.getModelContext()?.interactor?.onSelectionStart(event),
    [application],
  );

  const rootSx = useMemo(
    () => ({
      position: 'relative',
      flexGrow: 1,
      '.react-flow__pane': {
        cursor: `${cursor} !important`,
      },
      '.react-flow__node': { cursor: `${cursor} !important` },
    }),
    [cursor],
  );

  const onClickAway = useCallback(() => application?.getModelContext()?.interactor?.onDeSelectAll(), [application]);

  const onDragOver = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'move';
      const onDragOver = application?.getModelContext()?.interactor?.onDragOver;
      if (!onDragOver) return;
      handleDragEvent(onDragOver, e as unknown as ReactMouseEvent, [], {
        isModPressed,
      });
    },
    [application, handleDragEvent, isModPressed],
  );

  const openChat = useCallback(() => {
    setShowChatForModel(id);
  }, [id, setShowChatForModel]);

  const trackpadConfig = useMemo<ReactFlowProps>(() => {
    switch (mouseInputMode) {
      case 'mouse':
        return {
          panOnDrag: [1, 2],
          panOnScroll: true,
          zoomOnDoubleClick: false,
          zoomOnPinch: true,
          zoomOnScroll: false,
          selectionOnDrag: true,
          panOnScrollMode: PanOnScrollMode.Free,
          panOnScrollSpeed: 0.5,
          preventScrolling: true,
          zoomActivationKeyCode: 'Meta',
          panActivationKeyCode: 'Space',
        } satisfies ReactFlowProps;

      case 'trackpad':
        return {
          panOnDrag: false,
          panOnScroll: true,
          zoomOnDoubleClick: false,
          zoomOnPinch: true,
          zoomOnScroll: false,
          selectionOnDrag: true,
          panOnScrollMode: PanOnScrollMode.Free,
          panOnScrollSpeed: 0.5,
          preventScrolling: true,
          zoomActivationKeyCode: 'Meta',
          panActivationKeyCode: 'Space',
        } satisfies ReactFlowProps;
      default:
        throw new Error('Invalid mouse input mode');
    }
  }, [mouseInputMode]);

  useEffect(() => {
    application?.context.messageBus.sendInternal(LoadFileCommand, {
      fileId: id,
      fileType: FileType.Model,
      fileExtension: fileType,
    });
    return () => {
      application?.context.messageBus.sendInternal(DisconnectFileCommand, {
        fileId: id,
        fileType: FileType.Model,
        fileExtension: fileType,
      });
    };
  }, [application, fileType, id]);

  useEffect(() => {
    return () => {
      application?.getModelContext()?.interactor?.onDeSelectAll();
    };
  }, [application]);

  // Rethink this. Currently, we are relying on mouse up + selected entity to trigger a drag and drop
  useWindowEvent('mouseup', () => {
    if (toolbar.selectedEntity) dispatchCommand('SetToolbarStateCommand', { key: 'selectedEntity', value: '' });
  });

  const onMoveStart = useCallback<OnMoveStart>(
    (e) => {
      // if there is an event, it means it's a move by the user
      // else it is a programmatic move (probably following another user)
      if (e) {
        dispatchCommand('FollowUserCommand', { fileId: id, clientId: null });
      }
    },
    [dispatchCommand, id],
  );

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box sx={rootSx}>
        <FollowingUserOverlay fileId={id} />
        <ReactFlow
          {...reactFlowConfig}
          {...trackpadConfig}
          id={id}
          nodes={nodes}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onEdgesChange={onEdgesChange}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onNodeClick={onNodeClick}
          onNodeDrag={onNodeDrag}
          onNodeDragStart={onNodeDragStart}
          onNodeDragStop={onNodeDragStop}
          onNodesChange={onNodesChange}
          onSelectionChange={onSelectionChange}
          onSelectionEnd={onSelectionEnd}
          onSelectionStart={onSelectionStart}
          onlyRenderVisibleElements={false}
          panOnScrollMode={PanOnScrollMode.Free}
          proOptions={proOptions}
          selectionMode={SelectionMode.Full}
          connectionMode={ConnectionMode.Loose}
          snapGrid={snapGrid}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onReconnect={onReconnect}
          edges={edges}
          onMoveStart={onMoveStart}
          // colorMode={isProd ?   'light':'system'}
        >
          <Panel position="top-right">
            <div className="flex gap-2">
              <Stack direction="row" alignItems="center" gap={1.5}>
                <SingleSourceModelToolbarAvatars id={id} />
              </Stack>
              {isAutoUser() ? <NavMenuLabels /> : null}
              {fileType === 'ndem' ? (
                <Button variant="secondary" onClick={openChat}>
                  <BotMessageSquare />
                </Button>
              ) : null}
            </div>
          </Panel>
          <Cursors id={id} />
          <CanvasControls />
          <Background />
          {DEBUG_CONFIG.workers ? (
            <Panel position="bottom-right">
              <AppsView />
            </Panel>
          ) : null}
        </ReactFlow>
        <ManageLabelsModal
          isOpen={showManageLabelsModal}
          onClose={() => setShowManageLabelsModal(false)}
          labels={labels}
          labelsListItemProps={labelsListItemProps}
        />
        <RestorePreviousVersionModal />
        <UploadLinkModal
          isOpen={showUploadLinkModal}
          onClose={() => setShowUploadLinkModal(false)}
          onUpload={onUpload}
        />
        <UrlPreviewModal />
        <WorkspaceResetStateModal isOpen={showWorkspaceResetModal} onClose={() => setShowWorkspaceResetModal(false)} />
      </Box>
    </ClickAwayListener>
  );
};

export const SingleSourceModelCanvas: FC<SingleSourceModelCanvasProps> = (props) => {
  const { id, fileType } = props;

  const annotator = useSingleSourceStore.use.annotator();
  const toolbar = useSingleSourceStore.use.toolbar();

  // const { dispatchCommand } = useCommandDispatch();

  return (
    <>
      {/*<Button*/}
      {/*  onClick={() => {*/}
      {/*    console.log('soama');*/}
      {/*    dispatchCommand('UpdateFileContentCommand', {*/}
      {/*      fileId: '8fee0985-a330-400c-b860-9b79d536704f/arEDPmQm8h-json',*/}
      {/*      fileType: FileType.Spec,*/}
      {/*      content: 'hello osama how are you doing!!!',*/}
      {/*    });*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Test File Overwriting*/}
      {/*</Button>*/}
      {DEBUG_CONFIG.fps ? <FpsView /> : null}
      {/* Important to keep outside reactflow provider to ensure that the flows' contexts don't mix */}
      {annotator?.showAnnotatorView ? (
        <Annotator annotator={annotator} />
      ) : (
        <ReactFlowProvider>
          <_SingleSourceModelCanvas id={id} fileType={fileType} />
          <CanvasToolbar toolbar={toolbar} />
        </ReactFlowProvider>
      )}
    </>
  );
};

function getFileType(url: string): UploadType | undefined {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp', '.tiff'];
  const fileExtensions = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt', '.csv', '.json'];
  const lowerCaseUrl = url.toLowerCase();

  for (const ext of imageExtensions) {
    if (lowerCaseUrl.includes(ext)) {
      return UploadType.Image;
    }
  }

  for (const ext of fileExtensions) {
    if (lowerCaseUrl.includes(ext)) {
      return UploadType.File;
    }
  }

  return UploadType.File;
}

const proOptions = { account: 'paid-pro', hideAttribution: true };
const snapGrid: SnapGrid = [0.01, 0.01];
const defaultEdgeOptions = { type: 'default' };
const deleteKeyCode = ['Backspace', 'Delete'];
const multiSelectionKeyCode = ['Shift', 'Meta'];
export const reactFlowConfig: ReactFlowProps = {
  connectionMode: ConnectionMode.Loose,
  defaultEdgeOptions: defaultEdgeOptions,
  deleteKeyCode: deleteKeyCode,
  elementsSelectable: true,
  maxZoom: 8,
  minZoom: 0,
  multiSelectionKeyCode: multiSelectionKeyCode,
  nodeDragThreshold: 1,
  onlyRenderVisibleElements: false,
  proOptions: proOptions,
  selectNodesOnDrag: true,
  selectionMode: SelectionMode.Full,
  snapToGrid: true,
  snapGrid: snapGrid,
  connectionRadius: 30,
};
