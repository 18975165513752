import { ShortcutItem, ShortcutItemProps } from '../shortcut-item/shortcut-item';
import { Table, TableBody } from '@xspecs/design-system';

export interface ShortcutSectionProps {
  name: string;
  shortcuts: ShortcutItemProps[];
}

export const ShortcutSection = (props: ShortcutSectionProps) => {
  const { name, shortcuts } = props;

  return (
    <div className="my-1">
      <p className="mb-1 font-sm">{name}</p>
      <Table data-testid="shortcut-section" className="mb-2">
        <TableBody>
          {shortcuts.map((shortcut) => (
            <ShortcutItem key={shortcut.name} {...shortcut} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
