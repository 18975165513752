import { ReactFlowState, useStore } from '@xyflow/react';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

const selector = (state: ReactFlowState) => state.domNode?.querySelector('.react-flow__renderer');

export const ReactFlowRendererPortal = ({ children }: { children: ReactNode }) => {
  const wrapperRef = useStore(selector);

  if (!wrapperRef) {
    return null;
  }

  return createPortal(children, wrapperRef);
};
