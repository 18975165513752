import { FileBase } from './FileBase';
import { Position } from '../types';

export enum FileType {
  Model = 'diagram',
  Doc = 'slate',
  Spec = 'monaco',
  Space = 'space',
}

export enum Status {
  Initial = 'Initial',
  Unknown = 'Unknown',
  Connecting = 'Connecting',
  Connected = 'Connected',
  Authenticated = 'Authenticated',
  Synced = 'Synced',
  Unsynced = 'Unsynced',
  Disconnected = 'Disconnected',
  AuthenticationFailed = 'AuthenticationFailed',
}

export enum FileEvents {
  open = 'open',
  connect = 'connect',
  authenticated = 'authenticated',
  authenticationFailed = 'authenticationFailed',
  status = 'status',
  message = 'message',
  outgoingMessage = 'outgoingMessage',
  synced = 'synced',
  close = 'close',
  destroy = 'destroy',
  disconnect = 'disconnect',
  update = 'update',
  awarenessUpdate = 'awarenessUpdate',
  awarenessChange = 'awarenessChange',
  stateless = 'stateless',
}

export type SerializedFile = {
  fileId: string;
  status: Status;
  clientId: string;
  type: FileType;
  activeUser: ActiveUser;
  file: FileBase;
};

export type ActiveUser = {
  name: string;
  picture: string;
  color: string;
  sub: string;
  id: string;
  position?: Position;
  dxDy?: Position;
  viewport?: { x: number; y: number; zoom: number };
};
