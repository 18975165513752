import { CSSProperties, FC, useCallback, useMemo } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../tabs/tabs';
import { X } from 'lucide-react';
import { LazyLog } from '@melloware/react-logviewer';
import { BuildLogState } from '@xspecs/single-source-model/src/read-models/build-log/BuildLog.types';
import { FormatLogPart } from '@xspecs/design-system/src/ui/deploy-logs/format-part';
import { Checkbox, useStoreContext } from '@xspecs/design-system';

type DeployLogsProps = {
  onClose: (type: string, open?: boolean) => void;
  logs: BuildLogState | undefined;
  type: string;
};

enum Tab {
  Logs = 'Logs',
}

export const DeployLogs: FC<DeployLogsProps> = (props) => {
  const { onClose, logs, type } = props;
  const { store } = useStoreContext();
  const follow = typeof store.use.panels()['logs'].follow === 'undefined' ? true : store.use.panels()['logs'].follow;
  const setPanels = store.use.setPanels();

  const content = useMemo(() => logs?.buildLogs.map((l) => l.log).join('\n'), [logs?.buildLogs]);

  const onChangeFollow = useCallback(
    (checked: boolean) => {
      setPanels('logs', 'follow', checked);
    },
    [setPanels],
  );

  return (
    <div className="w-full h-full bg-background flex gap-2 px-3 py-2.5">
      <div className="flex-1 h-full">
        <Tabs defaultValue={Tab.Logs} className="w-full h-full">
          <TabsList className="bg-transparent dark:bg-transparent">
            <TabsTrigger
              value={Tab.Logs}
              className="cursor-pointer py-0 bg-transparent data-[state=active]:shadow-none"
            >
              {type}
            </TabsTrigger>
          </TabsList>
          <TabsContent className="w-full h-full pb-14" value={Tab.Logs}>
            <LogsTab content={content} follow={follow} />
          </TabsContent>
        </Tabs>
      </div>
      <div className="flex gap-1">
        {/*<ListX size={16} className="text-icon cursor-pointer" />*/}
        <label
          htmlFor="follow"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Follow
        </label>
        <Checkbox id="follow" checked={follow} onCheckedChange={onChangeFollow} />
        <X size={16} className="text-icon cursor-pointer" onClick={() => onClose(type)} />
      </div>
    </div>
  );
};

type LogsTabProps = {
  content?: string;
  follow: boolean;
};

const LogsTab: FC<LogsTabProps> = (props) => {
  const { content, follow } = props;

  const style: CSSProperties = useMemo(
    () => ({ background: 'hsl(var(--background)) !important', overflowY: 'scroll' }),
    [],
  );

  return (
    <div className="h-full w-full">
      <LazyLog
        text={content}
        external={true}
        follow={follow}
        style={style}
        enableLinks
        extraLines={1}
        formatPart={FormatLogPart}
      />
    </div>
  );
};
