import { useSingleSourceStore } from '../store/single-source-store/single-source-store';
import { Organization } from '../state/types';
import { useCallback, useEffect } from 'react';
import { ORGANIZATIONS_QUERY } from '../graphql/queries';
import { apolloClient } from '../config/apollo/ApolloClient';
import { RegisterAppsCommand } from '@xspecs/single-source-model';
import { useApplication } from '../wrappers/application-context/application-context';

export const useActiveOrganization = () => {
  const { application } = useApplication();

  const organization: Organization | undefined = useSingleSourceStore.use.organization();
  const setOrganization = useSingleSourceStore.use.setOrganization();

  const refreshActiveOrganization = useCallback(async () => {
    if (!organization) return;
    const [response] = await apolloClient.refetchQueries({ include: [ORGANIZATIONS_QUERY] });
    const { organizations } = response.data;
    const foundOrganization = organizations.find((org) => org.id === organization.id);
    if (!foundOrganization) return;
    setOrganization(foundOrganization);
  }, [organization, setOrganization]);
  useEffect(() => {
    if (!organization || !application?.context) return;
    const apps: any[] = [];
    organization.registeredApps.forEach((app) => {
      if (!app.manifest) return;
      apps.push(app.manifest);
    });
    application.context.messageBus.sendInternal(RegisterAppsCommand, {
      apps,
    });
  }, [application?.context, organization]);

  return {
    organization,
    setOrganization,
    refreshActiveOrganization,
  } as const;
};
