import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { CreatedBy } from '../../entities/EntityBase';

export interface ModelSuggestionReadyParams extends IParams {
  createdBy: CreatedBy;
}

export interface ModelSuggestionReadyEventParams extends IParams {
  createdBy: CreatedBy;
}

export class ModelSuggestionGeneratedEvent extends EventBase {
  static eventType = 'ModelSuggestionGeneratedEvent';
  constructor(
    public readonly params: ModelSuggestionReadyEventParams,
    public readonly source = NotifyModelSuggestionReadyCommand,
  ) {
    super();
  }
}

export class NotifyModelSuggestionReadyCommand extends CommandBase<ModelSuggestionReadyParams> {
  execute(params: ModelSuggestionReadyParams): ModelSuggestionGeneratedEvent | CommandError {
    return new ModelSuggestionGeneratedEvent({
      createdBy: params.createdBy,
    });
  }
}
