import { useIntl } from 'react-intl';
import { Link2 } from 'lucide-react';
import { Button, Tooltip, TooltipContent, TooltipTrigger } from '@xspecs/design-system';

export type CopyLinkButtonProps = {
  onClick: () => void;
};

export const CopyLinkButton = (props: CopyLinkButtonProps) => {
  const { onClick } = props;

  const { formatMessage: f } = useIntl();

  return (
    <Tooltip disableHoverableContent>
      <TooltipTrigger asChild>
        <Button size="icon" variant="ghost" onClick={onClick}>
          <Link2 height={16} width={16} />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{f({ id: 'copy-link' })}</TooltipContent>
    </Tooltip>
  );
};
