import { createContext, useContext, useEffect } from 'react';
import { useCommandDispatch, useStoreContext } from '@xspecs/design-system';
import { UITheme } from '@xspecs/single-source-model';

const ThemeContext = createContext({ theme: '', toggleTheme: () => {} });

export const ThemeProvider = ({ children }: { children: any }) => {
  const { store } = useStoreContext();
  const storeTheme = store.use.theme();
  const { dispatchCommand } = useCommandDispatch();

  useEffect(() => {
    document.body.classList.remove('light', 'dark');
    document.body.classList.add(storeTheme);
  }, [storeTheme]);

  const toggleTheme = () => {
    const newTheme = storeTheme === UITheme.LIGHT ? UITheme.DARK : UITheme.LIGHT;
    dispatchCommand('ToggleThemeCommand', {
      theme: newTheme,
    });
  };

  return <ThemeContext.Provider value={{ theme: storeTheme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
