import { IStore } from './Store';
import { Filter } from '../entities/Filter';
import { Label } from '../entities/assets/Label';
import { EntityDetailsState } from '../read-models/entity-details/EntityDetails';
import { CommandError } from '../ErrorStore';
import { AnnotatorState } from '../read-models/annotator/Annotator';
import { AssetExplorerState } from '../read-models/asset-explorer/AssetExplorerItem';
import { ExplorerState } from '../read-models/explorer/ExplorerItem';
import { AttachmentsByAssetIdState } from '../read-models/attachments/Attachments.types';
import { FiltersMenuState } from '../read-models/filters-menu/FiltersMenu';
import { ToolbarState } from '../read-models/toolbar/ToolbarState';
import { FileTreeState } from '../read-models/file-tree/FileTree.types';
import { IdeState } from '../read-models/ide/Ide.types';
import { BuildLogState } from '../read-models/build-log/BuildLog.types';
import { FollowedUserState } from '../read-models/followed-user/FollowedUser';
import { UITheme } from '../interactors/types';
import { SuggestionsTreeState } from '../read-models/suggestions/SuggestionsTree';

export class ZustandStore implements IStore {
  constructor(private readonly store: any) {}

  suggestionsTree: SuggestionsTreeState;
  setSuggestionsTree: (suggestionsTree: SuggestionsTreeState) => void;

  followedUser: FollowedUserState;
  setFollowedUser: (followedUser: FollowedUserState) => void;

  setSelectionMode: () => void;
  setShowUploadFileModal: (showUploadFileModal: string) => void;

  setStateField: (...args: any) => void;

  setFileById: (fileId: string, file: any) => void;
  setActiveUsersByFile: (fileId: any, users: any[]) => void;

  graph: { nodes: any[]; edges: any[] };
  explorerResult: { items: any[]; error?: string | undefined };
  annotator: AnnotatorState;
  assets: any[];
  fileTree: FileTreeState;
  ide: IdeState;
  labels: Label[];
  filtersMenu: FiltersMenuState;
  unsavedFilter: Filter;
  savedFilters: Filter[];
  appliedSavedFilter: Filter;
  entityDetails: EntityDetailsState;
  errors: CommandError[];
  toolbar: ToolbarState;
  constructToPanTo: string | undefined;
  showResolvedThreads: boolean;
  assetExplorer: AssetExplorerState;
  explorer: ExplorerState;
  attachmentsByAssetId: AttachmentsByAssetIdState;
  palette: any;
  states: any;
  isLoaded: boolean;
  testApp: any;
  buildLog: BuildLogState;
  devMode: boolean;
  theme: UITheme.LIGHT;

  setBuildLog: (buildLogState: BuildLogState) => void;
  setFileTree: (fileTree: FileTreeState) => void;
  setIde: (ide: IdeState) => void;
  setIsLoaded: (isLoaded: boolean) => void;

  setDevMode: (devMode: boolean) => void;

  setShowUploadLinkModal: (showUploadLinkModal: boolean) => void;

  setToolbar: (toolbar: ToolbarState) => void;

  setState: (state: any) => void;

  setFiltersMenu: (filtersMenu: FiltersMenuState) => void;

  setPalette: (palette: any) => void;

  setAppState: (appName: string, state: object) => void;

  setAssets: (assets: any[]) => void;

  setAnnotator: (annotator: AnnotatorState) => void;

  setErrors: (errors: CommandError[]) => void;

  setShowResolvedThreads: (showResolvedThreads: boolean) => void;

  setEntityDetails: (entity: EntityDetailsState) => void;

  setLabels: (labels: Label[]) => void;

  setUnsavedFilter: (Filter: Filter) => void;

  getState() {
    return this.store.getState();
  }

  setGraph(graph: { nodes: any[]; edges: any[] }) {
    this.store.getState().setGraph(graph);
  }

  setExplorerResult(result: { items: any[]; error?: string }) {
    this.store.getState().setExplorerResult(result);
  }

  setSavedFilters(filters: Filter[]): void {
    this.store.getState().setSavedFilters(filters);
  }

  setAppliedSavedFilter(filter: Filter): void {
    this.store.getState().setAppliedSavedFilter(filter);
  }

  setAssetExplorer(assetExplorer: AssetExplorerState): void {
    this.store.getState().setAssetExplorer(assetExplorer);
  }

  setExplorer(explorer: ExplorerState): void {
    this.store.getState().setExplorer(explorer);
  }

  setAttachmentsByAssetId(attachmentsByAssetId: AttachmentsByAssetIdState): void {
    this.store.getState().setAttachmentsByAssetId(attachmentsByAssetId);
  }

  setConstructToPanTo(constructToPanTo: string) {
    this.store.getState().setConstructToPanTo(constructToPanTo);
  }

  setTheme(theme: UITheme) {
    this.store.getState().setTheme(theme);
  }
}
