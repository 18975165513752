import { Button, Stack, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';

export const AppsView = () => {
  const [appName, setName] = useState('jira');
  const [scriptUrl, setScriptUrl] = useState(
    // @ts-ignore
    new URL('./jira-worker', import.meta.url).href,
    // 'https://narrative-apps-test.s3.us-east-1.amazonaws.com/jira-worker.js',
  );
  const [appStatus, setAppStatus] = useState<Record<string, string>>({});

  const unsubscribe = useRef(() => {});
  const [store, setStore] = useState<Record<string, object>>({});

  const { application } = useApplication();

  useEffect(() => {
    Object.entries(appStatus).forEach(([appName, status]) => {
      if (status) {
        application?.context.workerManager.addWorker({
          appName,
          workerScriptUrl: status,
        });
        unsubscribe.current();
        unsubscribe.current = useSingleSourceStore.subscribe((state) => {
          setStore((prev) => ({
            ...prev,
            [appName]: state[appName],
          }));
        });
      }
    });
  }, [appStatus, application?.context.workerManager]);

  return (
    <Stack
      sx={{
        border: '1px solid grey',
        borderRadius: 4,
        width: 400,
        p: 1,
        bgcolor: 'white',
        gap: 2,
      }}
      justifyContent="space-between"
    >
      <TextField placeholder="App name" value={appName} onChange={(e) => setName(e.target.value)} />
      <TextField placeholder="Script URL" value={scriptUrl} onChange={(e) => setScriptUrl(e.target.value)} />
      <Button
        variant="contained"
        disabled={!appName || !scriptUrl}
        onClick={() => {
          setAppStatus((prev) => ({
            ...prev,
            [appName]: scriptUrl,
          }));
        }}
      >
        Start Worker
      </Button>

      {Object.entries(appStatus).map(([appName, status]) => (
        <Stack key={appName} direction="row" justifyContent="space-between" mt={6} mb={1}>
          <div>{appName}</div>
          <Button
            variant="contained"
            onClick={() => {
              setAppStatus((prev) => {
                const next = { ...prev };
                delete next[appName];
                return next;
              });
              application?.context.workerManager.removeWorker({ appName });
            }}
          >
            Stop Worker
          </Button>
        </Stack>
      ))}
      {Object.entries(appStatus).map(([appName, status]) => (
        <Button
          key={appName}
          onClick={() => {
            application?.context.workerManager.publishMessage({
              appName,
              message: {
                task: 'reverseString',
                data: appName,
              },
            });
          }}
        >
          Reverse string
        </Button>
      ))}

      <div>
        Result
        <pre>{JSON.stringify(store, null, 2)}</pre>
      </div>
    </Stack>
  );
};
