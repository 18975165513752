import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { Application } from '@xspecs/single-source-model';
import { StoreProvider, StoreWithSelectors } from '@xspecs/design-system';
import { useTrackUserLoggedIn } from '../../hooks/use-track-user-logged-in';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';

export type ApplicationContextProps = {
  application: Application | null;
};

export type ApplicationContextValue = ApplicationContextProps & {
  dispatch: (command: string, params?: Record<string, unknown>) => void;
};

const ApplicationContext = createContext<ApplicationContextValue>({
  application: null,
  dispatch: () => {},
});

export const ApplicationProvider: FC<PropsWithChildren<ApplicationContextProps>> = ({ application, children }) => {
  const dispatch = useCallback(
    (command: string, params = {}) => {
      application?.context?.messageBus.send(command, params);
    },
    [application?.context?.messageBus],
  );

  const value = useMemo<ApplicationContextValue>(
    () => ({
      dispatch,
      application,
    }),
    [application, dispatch],
  );

  useTrackUserLoggedIn();

  return (
    <ApplicationContext.Provider value={value}>
      <StoreProvider store={useSingleSourceStore as unknown as StoreWithSelectors}>{children}</StoreProvider>
    </ApplicationContext.Provider>
  );
};

export const useApplication = () => useContext<ApplicationContextValue>(ApplicationContext);

export const useCommandDispatch = () => {
  const { dispatch } = useApplication();

  const useMock = window.location.href.includes('storybook') || window.location.href.includes('localhost:6006');

  return useMock
    ? (...args) => {
        // eslint-disable-next-line no-console
        console.log('Command dispatch is disabled in production', args);
      }
    : dispatch;
};
