import { useIntl } from 'react-intl';
import { DocEditor } from '../../../doc/editor/doc-editor';
import { Spec } from '../../../spec/spec';
import { JsonEditor } from '../../entity-details/json-editor';
import { EntityDetailsEditor } from 'domain/single-source-model/src/read-models/entity-details/EntityDetails';
import { useApplication } from '../../../../wrappers/application-context/application-context';
import { UpdateEntityPayloadCommand } from '@xspecs/single-source-model';
import { GherkinEditor, Schemas } from '../../entity-details/gherkin-editor';
import { useCallback } from 'react';
import { CommandStrategy } from '@xspecs/single-source-model';
import { pascalcase } from 'pascalcase';

type EntityDocProps = {
  entityId: string;
  editorType: EntityDetailsEditor;
  schemas?: Schemas;
  name?: string;
  commandStrategy?: CommandStrategy;
  entityType?: string;
};

export const EntityDescription = (props: EntityDocProps) => {
  const { entityId, editorType, schemas, name, commandStrategy, entityType } = props;

  const { formatMessage: f } = useIntl();
  const { application } = useApplication();

  const onEditorChange = useCallback(
    (value: string) => {
      application?.context.messageBus.send(UpdateEntityPayloadCommand, {
        entityId,
        payload: value,
      });
    },
    [application?.context.messageBus, entityId],
  );

  const id = `${entityId}-${editorType}`;
  switch (editorType) {
    case EntityDetailsEditor.SPEC:
    case EntityDetailsEditor.GQL:
      return <Spec id={id} />;
    case EntityDetailsEditor.JSON:
      return (
        <JsonEditor
          id={id}
          onEditorChange={onEditorChange}
          showJsonRepresentation
          initialCommandStrategy={commandStrategy}
          showCommandStrategy={entityType === 'Command'}
          initialValue={
            entityType === 'Command' || entityType === 'Event'
              ? `{
  "__typename": "${pascalcase(name)}",
  "id": "abc123"
}`
              : undefined
          }
        />
      );
    case EntityDetailsEditor.SLATE:
      return <DocEditor documentId={id} placeholder={f({ id: 'description' })} />;
    case EntityDetailsEditor.GHERKIN:
      return (
        <GherkinEditor
          id={id}
          schemas={schemas}
          onEditorChange={onEditorChange}
          name={name}
          showJsonRepresentation
          initialValue={`
Scenario: ${name}
 
Given

When

Then 
`}
        />
      );
    default:
      throw new Error(`Unknown editor type: ${editorType}`);
  }
};
