import { Avatar, AvatarGroup, Box, Tooltip, Typography } from '@mui/material';
import { memo, RefObject, useCallback, useMemo, useRef, useState } from 'react';
import { useOnClickOutside } from '@udecode/plate-common';

export type Avatar = {
  id: string;
  sub: string;
  name: string;
  picture: string;
  color: string;
};

export type AvatarsProps = {
  users: Avatar[];
  onAvatarClick?: (id: string) => void;
  isFollowed?: (id: string) => boolean;
};

const initialsRegex = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
const getInitials = (name: string) => {
  const initials = [...name.matchAll(initialsRegex)] || [];

  return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
};

const MAX_AVATARS_TO_DISPLAY = 5;

export const Avatars = memo(
  (props: AvatarsProps) => {
    const { users, onAvatarClick, isFollowed } = props;

    const [openId, setOpenId] = useState('');

    const avatars = useMemo(
      () => users.slice(0, MAX_AVATARS_TO_DISPLAY).map((user) => ({ ...user, initials: getInitials(user.name) })),
      [users],
    );

    const additionalUsers = useMemo(
      () => (users.length > MAX_AVATARS_TO_DISPLAY ? users.slice(MAX_AVATARS_TO_DISPLAY) : []),
      [users],
    );

    const setOpenTooltip = useCallback((id: string) => {
      setOpenId(id);
    }, []);

    const onClick = useCallback(
      (id: string) => {
        setOpenTooltip(id);
        onAvatarClick?.(id);
      },
      [onAvatarClick, setOpenTooltip],
    );

    const closeTooltip = useCallback(() => {
      setOpenId('');
    }, []);

    const resolveFontSizeForExtraUsersCount = useMemo(() => {
      if (additionalUsers.length < 10) return 20;
      if (additionalUsers.length < 100) return 16;
      return 12;
    }, [additionalUsers.length]);

    const avatarRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(closeTooltip, { refs: [avatarRef] });

    const additionalUsersCountSx = useMemo(
      () => ({ fontSize: resolveFontSizeForExtraUsersCount, fontWeight: '500' }),
      [resolveFontSizeForExtraUsersCount],
    );

    return (
      <AvatarGroup data-testid="avatars" max={1000} sx={rootSx}>
        {avatars.map(({ id, name, picture, initials, color }) => (
          <AvatarItem
            key={`Avatars${id}`}
            id={id}
            open={openId === id}
            name={name}
            closeTooltip={closeTooltip}
            onMouseOver={setOpenTooltip}
            color={color}
            picture={picture}
            onClick={onClick}
            initials={initials}
            avatarRef={avatarRef}
            isFollowed={isFollowed?.(id)}
          />
        ))}
        {additionalUsers.length > 0 ? (
          <Tooltip
            PopperProps={additionalUsersTooltipPaperProps}
            title={
              <Box sx={additionalUsersTitleSx}>
                {additionalUsers.map(({ id, name, color }) => (
                  <Typography key={id} fontSize={12} color={color}>
                    {name}
                  </Typography>
                ))}
              </Box>
            }
          >
            <Avatar sx={additionalUserCountRootSx}>
              <Typography sx={additionalUsersCountSx}>+{additionalUsers.length}</Typography>
            </Avatar>
          </Tooltip>
        ) : null}
      </AvatarGroup>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.users.length !== nextProps.users.length) return false;
    return prevProps.users.every((user, index) => user.id === nextProps.users[index].id);
  },
);
Avatars.displayName = 'Avatars';

const rootSx = { minWidth: 50 };
const additionalUsersTitleSx = {
  backgroundColor: 'background.paper',
  color: 'primary.main',
  border: '1px solid #ccc',
  px: 1,
  borderRadius: 1,
  mt: -0.5,
  maxHeight: '300px',
  overflowY: 'scroll',
};
const additionalUsersTooltipPaperProps = { sx: { '& .MuiTooltip-tooltip': { backgroundColor: 'white' } } };
const additionalUserCountRootSx = { border: '2px solid' };

type AvatarItemProps = {
  id: string;
  closeTooltip: () => void;
  open: boolean;
  name: string;
  onMouseOver: (id: string) => void;
  color: string;
  picture: string;
  onClick: (id: string) => void;
  initials: string;
  avatarRef: RefObject<HTMLDivElement>;
  isFollowed?: boolean;
};

const AvatarItem = memo(
  ({
    id,
    open,
    name,
    closeTooltip,
    onMouseOver: onMouseOverProp,
    color,
    picture,
    onClick: onClickProp,
    initials,
    avatarRef,
    isFollowed,
  }: AvatarItemProps) => {
    const PaperProps = useMemo(
      () => ({
        disablePortal: true,
        sx: {
          '& .MuiTooltip-tooltip': {
            border: `1px solid ${color}`,
            color,
            backgroundColor: 'white',
          },
        },
      }),
      [color],
    );

    const onMouseOver = useCallback(() => {
      onMouseOverProp(id);
    }, [id, onMouseOverProp]);

    const avatarSx = useMemo(
      () => ({
        backgroundColor: color,
        cursor: 'pointer',
        border: `${isFollowed ? 3 : 2}px solid ${color} !important`,
      }),
      [color, isFollowed],
    );

    const onClick = useCallback(() => {
      onClickProp(id);
    }, [id, onClickProp]);

    return (
      <Tooltip
        data-testid="avatar-name"
        title={name}
        onClose={closeTooltip}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        onMouseOver={onMouseOver}
        onMouseLeave={closeTooltip}
        PopperProps={PaperProps}
      >
        <Avatar data-testid="avatar" alt={name} src={picture} sx={avatarSx} onClick={onClick} ref={avatarRef}>
          <Typography data-testid="avatar-initials">{initials}</Typography>
        </Avatar>
      </Tooltip>
    );
  },
);
AvatarItem.displayName = 'AvatarItem';
