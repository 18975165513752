import { matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { RoutePaths } from '../config/route-paths/route-paths';
import { AuthReturnRoute } from './auth-return-route/auth-return-route';
import { ChooseOrganizationRoute } from './choose-organization-route/choose-organization-route';
import { ConfirmEmailRoute } from './confirm-email-route/confirm-email-route';
import { CreateOrganizationRoute } from './create-organization-route/create-organization-route';
import { OrganizationRoute } from './home-route/organization-route';
import { InvitePeopleRoute } from './invite-people-route/invite-people-route';
import { LoginRoute } from './login-route/login-route';
import { PrivateRoute } from './private-route/private-route';
import { NoAccessRoute } from './no-access-route/no-access-route';
import { SpaceRoute } from './space-route/space-route';
import { FileRoute } from './space-route/file-route/file-route';
import { SettingsRoute } from './settings-route/settings-route';
import { useTrackEvents } from '../hooks/use-track-events';
import { Suspense, useEffect, useMemo, useRef } from 'react';
import { SwagShopRoute } from './swag-shop/swag-shop';
import { Analytics } from '@xspecs/single-source-model';
import { useActiveOrganization } from '../hooks/use-active-organization';
import { useActiveWorkspace } from '../hooks/use-active-workspace';
import { Organization, Workspace } from '../state/types';
import { SpacesRoute } from './spaces-route/spaces-route';
import { Loading } from '../components/loading/loading';

const TrackPageView = ({ pageType, children, path }) => {
  const location = useLocation();
  const { trackPageView } = useTrackEvents();
  const match = useMemo(() => matchPath({ path, end: true }, location.pathname), [location.pathname, path]);

  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();

  const selectedOrganizationRef = useRef<Organization | undefined>();
  const selectedWorkspaceRef = useRef<Workspace | undefined>();

  useEffect(() => {
    selectedOrganizationRef.current = organization;
  }, [organization]);

  useEffect(() => {
    selectedWorkspaceRef.current = workspace;
  }, [workspace]);

  useEffect(() => {
    if (match) {
      Analytics.getInstance().trackPageView(
        location.pathname,
        document.title,
        pageType,
        selectedOrganizationRef.current?.name,
        selectedWorkspaceRef.current?.name,
      );
    }
  }, [pageType, trackPageView, location, match]);

  return children;
};

export const AppRoutes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path={RoutePaths.Xolvio}>
          <Route
            path={RoutePaths.SwagShop}
            element={
              <TrackPageView pageType="swag_shop_page" path={RoutePaths.SwagShop}>
                <SwagShopRoute />
              </TrackPageView>
            }
          />
        </Route>
        <Route
          path={RoutePaths.Login}
          element={
            <TrackPageView pageType="login_page" path={RoutePaths.Login}>
              <LoginRoute />
            </TrackPageView>
          }
        />
        <Route
          path={RoutePaths.AuthReturn}
          element={
            <TrackPageView pageType="auth_return_page" path={RoutePaths.AuthReturn}>
              <AuthReturnRoute />
            </TrackPageView>
          }
        />
        <Route
          path={RoutePaths.ConfirmEmail}
          element={
            <TrackPageView pageType="confirm_email_page" path={RoutePaths.ConfirmEmail}>
              <ConfirmEmailRoute />
            </TrackPageView>
          }
        />
        <Route
          path={RoutePaths.NoAccess}
          element={
            <TrackPageView pageType="no_access_page" path={RoutePaths.NoAccess}>
              <NoAccessRoute />
            </TrackPageView>
          }
        />
        <Route path={RoutePaths.Default} element={<PrivateRoute />}>
          <Route
            index
            element={
              <TrackPageView pageType="choose_organization_page" path={RoutePaths.ChooseOrganization}>
                <Navigate to={RoutePaths.ChooseOrganization} />
              </TrackPageView>
            }
          />
          <Route path={RoutePaths.Organization} element={<OrganizationRoute />}>
            <Route
              path={RoutePaths.Space}
              element={
                <TrackPageView pageType="single_source_diagram" path={RoutePaths.Space}>
                  <SpaceRoute />
                </TrackPageView>
              }
            >
              <Route
                path={RoutePaths.File}
                element={
                  <TrackPageView pageType="file_page" path={RoutePaths.File}>
                    <FileRoute />
                  </TrackPageView>
                }
              />
            </Route>
            <Route
              path={RoutePaths.Settings}
              element={
                <TrackPageView pageType="settings_page" path={RoutePaths.Settings}>
                  <SettingsRoute />
                </TrackPageView>
              }
            />
            <Route
              path={RoutePaths.Spaces}
              element={
                <TrackPageView pageType="spaces_page" path={RoutePaths.Spaces}>
                  <SpacesRoute />
                </TrackPageView>
              }
            />
            <Route
              path={RoutePaths.InvitePeople}
              element={
                <TrackPageView pageType="invite_people_page" path={RoutePaths.InvitePeople}>
                  <InvitePeopleRoute />
                </TrackPageView>
              }
            />
          </Route>
          <Route
            path={RoutePaths.ChooseOrganization}
            element={
              <TrackPageView pageType="choose_organization_page" path={RoutePaths.ChooseOrganization}>
                <ChooseOrganizationRoute />
              </TrackPageView>
            }
          />
          <Route
            path={RoutePaths.CreateOrganization}
            element={
              <TrackPageView pageType="create_organization_page" path={RoutePaths.CreateOrganization}>
                <CreateOrganizationRoute />
              </TrackPageView>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <TrackPageView pageType="not_found_page" path="*">
              <div>{'Not found'}</div>
            </TrackPageView>
          }
        />
      </Routes>
    </Suspense>
  );
};
