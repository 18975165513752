import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { RESOLVE_THREAD_MUTATION } from '../../../../../../graphql/mutations';
import { AppTypeEvent, useTrackEvents } from '../../../../../../hooks/use-track-events';
import { useActiveWorkspace } from '../../../../../../hooks/use-active-workspace';
import { useApplication } from '../../../../../../wrappers/application-context/application-context';
import { Button, Tooltip, TooltipContent, TooltipTrigger } from '@xspecs/design-system';
import { X, Check, MessageSquareDot } from 'lucide-react';

type ThreadHeaderProps = {
  threadId: string;
  onClose: () => void;
  disableResolve?: boolean;
  isResolved: boolean;
};

export const ThreadHeader = (props: ThreadHeaderProps) => {
  const { threadId, onClose, disableResolve, isResolved } = props;

  const { formatMessage: f } = useIntl();

  const [resolveThread] = useMutation(RESOLVE_THREAD_MUTATION);

  const { workspace } = useActiveWorkspace();

  const { trackEvent } = useTrackEvents();

  const { application } = useApplication();
  const model = application?.context;

  const onResolve = useCallback(async () => {
    if (!workspace) return;
    if (isResolved) {
      application?.getModelContext()?.threadInteractor.unresolvedThread(threadId);
      trackEvent(AppTypeEvent.CommentThreadUnResolved, { threadId: threadId });
    } else {
      application?.getModelContext()?.threadInteractor.resolveThread(threadId);
      await resolveThread({ variables: { args: { id: threadId, workspaceId: workspace.id } } });
      trackEvent(AppTypeEvent.CommentThreadResolved, { threadId: threadId });
    }
  }, [workspace, isResolved, application, threadId, trackEvent, resolveThread]);

  const Icon = isResolved ? MessageSquareDot : Check;

  return (
    <div className="w-full h-6 flex items-center justify-end">
      {!disableResolve ? (
        <Tooltip>
          <TooltipTrigger>
            <Button variant="ghost" size="icon" onClick={onResolve} className="h-8 w-8">
              <Icon fontSize="inherit" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>{f({ id: isResolved ? 'unresolve' : 'resolve' })}</p>
          </TooltipContent>
        </Tooltip>
      ) : null}
      <Tooltip>
        <TooltipTrigger>
          <Button variant="ghost" size="icon" onClick={onClose} className="h-8 w-8">
            <X />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{f({ id: 'close' })}</p>
        </TooltipContent>
      </Tooltip>
    </div>
  );
};
