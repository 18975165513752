import { useCallback } from 'react';
import { useKeyboardShortcuts } from './shortcuts';
import { useIntl } from 'react-intl';
import { ShortcutSection } from './shortcut-section/shortcut-section';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@xspecs/design-system';

export const KeyboardShortcutsModal = () => {
  const open = useSingleSourceStore.use.showKeyboardShortcutsModal();
  const closeModal = useSingleSourceStore.use.setShowKeyboardShortcutModal();
  const { shortcuts } = useKeyboardShortcuts();
  const { formatMessage: f } = useIntl();

  const onOpenChange = useCallback(
    (open: boolean) => {
      closeModal(open);
    },
    [closeModal],
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle> {f({ id: 'shortcuts' })}</DialogTitle>
        </DialogHeader>

        {shortcuts.map((section) => (
          <ShortcutSection key={section.name} {...section} />
        ))}
      </DialogContent>
    </Dialog>
  );
};
