import { useOutletContext } from 'react-router-dom';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { Typography } from '@mui/material';
import { GherkinEditor } from '../../../components/single-source-model/entity-details/gherkin-editor';
import { DocEditor } from '../../../components/doc/editor/doc-editor';
import { JsonEditor } from '../../../components/single-source-model/entity-details/json-editor';
import { SingleSourceModel } from '../../../components/single-source-model';
import { GraphqlEditor } from '../../../components/single-source-model/entity-details/graphql-editor';
import { TxtEditor } from '../../../components/single-source-model/entity-details/txt-editor';
import { HAS_USER_VISITED, isUrl } from '../../../lib/utils';
import { FileUrlEditor } from '../../../components/single-source-model/entity-details/file-url-editor';
import clsx from 'clsx';
import { SingleSourceModelToolbarAvatars } from '../../../components/single-source-model/toolbar/avatars/single-source-model-toolbar-avatars';
import { useFileIsLoaded } from '../../../hooks/use-file-is-loaded';
import { FileType } from '@xspecs/single-source-model';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { useEffect } from 'react';

const map = {
  feature: {
    component: GherkinEditor,
    fileType: FileType.Spec,
  },
  doc: {
    component: DocEditor,
    fileType: FileType.Doc,
  },
  rtf: {
    component: DocEditor,
    fileType: FileType.Doc,
  },
  md: {
    component: DocEditor,
    fileType: FileType.Doc,
  },
  graphql: {
    component: GraphqlEditor,
    fileType: FileType.Spec,
  },
  json: {
    component: JsonEditor,
    fileType: FileType.Spec,
  },
  ndd: {
    component: SingleSourceModel,
    fileType: FileType.Model,
  },
  em: {
    component: SingleSourceModel,
    fileType: FileType.Model,
  },
  ndem: {
    component: SingleSourceModel,
    fileType: FileType.Model,
  },
  txt: {
    component: TxtEditor,
    fileType: FileType.Spec,
  },
};

export const FileRoute = () => {
  const { fileId } = useOutletContext<{ fileId: string }>();

  const { application } = useApplication();
  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();

  const fileTree = useSingleSourceStore.use.fileTree();
  const file = fileTree.items?.find((item) => item.id === fileId);
  const setShowMouseTrackpadSettings = useSingleSourceStore.use.setShowMouseTrackpadSettings();

  const { synced } = useFileIsLoaded(fileId);
  const isNotModel = !['ndd', 'em', 'ndem'].includes(file?.data.fileType ?? '');

  useEffect(() => {
    const hasVisited = localStorage.getItem(HAS_USER_VISITED);
    if (!hasVisited && !isNotModel) {
      setShowMouseTrackpadSettings(true);
    }

    return () => {
      localStorage.setItem(HAS_USER_VISITED, 'true');
    };
  }, [isNotModel, setShowMouseTrackpadSettings]);

  const getContent = () => {
    const content = file?.data.content;
    if (!file) return null;
    const key = file.id + file.data.timestamp;
    if (content && isUrl(content)) {
      return <FileUrlEditor key={key} url={content} fileType={file.data.fileType} />;
    }

    const Component = file.data.fileType ? map[file.data.fileType].component : null;
    return Component ? (
      <Component key={key} id={fileId} fileType={file.data.fileType} />
    ) : (
      <TxtEditor key={key} id={fileId} />
    );
  };

  if (!file) {
    return null;
  }

  return (
    <div className={clsx('w-full h-full bg-background', isNotModel && 'p-3 text-primary')}>
      {file ? (
        <div className="h-full w-full">
          {isNotModel ? (
            <div>
              <SingleSourceModelToolbarAvatars id={fileId} />
            </div>
          ) : null}
          {getContent()}
        </div>
      ) : (
        <Typography>Please select file</Typography>
      )}
    </div>
  );
};
