import { NarrativeSchemesBuilder } from './narrative/NarrativeSchemesBuilder';
import { EventModelingSchemeBuilder } from './eventModeling/EventModelingSchemeBuilder';
import { NarrativeDrivenEventModelingSchemeBuilder } from './eventModeling/NarrativeDrivenEventModelingSchemeBuilder';
import { Scheme } from 'narrative-studio-sdk';
import { SchemeName } from '../commands/apps/SetSchemeCommand';

export class AppSchemesBuilder {
  static Build(schemeName: SchemeName): Scheme[] {
    if (schemeName === SchemeName.Narrative) {
      return NarrativeSchemesBuilder.build();
    } else if (schemeName === SchemeName.EventModelling) {
      return [EventModelingSchemeBuilder.build()];
    } else if (schemeName === SchemeName.NarrativeAndEventModelling) {
      return [NarrativeDrivenEventModelingSchemeBuilder.build()];
    }
    return NarrativeSchemesBuilder.build();
  }
}
