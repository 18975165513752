import { ThreadHeader } from '../header/thread-header';
import { CommentTextArea } from '../comment-text-area/comment-text-area';
import { CommentsList, CommentsListHandle } from './comments/list/comments-list';
import { Comment } from '@xspecs/single-source-model';
import { useCallback, useEffect, useRef } from 'react';
import { Value } from '@udecode/plate-common';
import { sid } from '@xspecs/short-id';
import { useAuth } from '../../../../../../auth';
import { useMutation } from '@apollo/client';
import { MENTION_USERS_MUTATION } from '../../../../../../graphql/mutations';
import { useIntl } from 'react-intl';
import { AppTypeEvent, useTrackEvents } from '../../../../../../hooks/use-track-events';
import { useActiveWorkspace } from '../../../../../../hooks/use-active-workspace';
import { useApplication } from '../../../../../../wrappers/application-context/application-context';

type ThreadProps = {
  threadId: string;
  comments: Comment[];
  isResolved: boolean;
  onClose: () => void;
};

export const Thread = (props: ThreadProps) => {
  const { onClose, threadId, comments, isResolved } = props;

  const { formatMessage: f } = useIntl();

  const commentsListHandleRef = useRef<CommentsListHandle>(null);

  const { workspace } = useActiveWorkspace();

  const { user } = useAuth();

  const { trackEvent } = useTrackEvents();

  const { application } = useApplication();
  const model = application?.context;

  const [mentionUsers] = useMutation(MENTION_USERS_MUTATION);

  const hasComments = comments.length !== 0;

  useEffect(() => {
    trackEvent(AppTypeEvent.CommentThreadViewed, { threadId });
  }, [trackEvent, threadId]);

  const onSubmitComment = useCallback(
    async (value: Value, id: string, mentionedUserIds: string[]) => {
      if (!workspace) return;
      const commentId = sid();
      application
        ?.getModelContext()
        ?.threadInteractor.addCommentToThread({ threadId: id, value: value, createdBy: user.sub, commentId });
      trackEvent(AppTypeEvent.CommentAdded, { threadId: id, commentId });
      commentsListHandleRef.current?.scrollToBottom();
      if (mentionedUserIds.length > 0)
        await mentionUsers({
          variables: {
            args: {
              threadId: id,
              commentId,
              mentionedUserIds: mentionedUserIds,
              workspaceId: workspace.id,
            },
          },
        });
      mentionedUserIds.forEach((userId) => {
        trackEvent(AppTypeEvent.CommentUserMentioned, { threadId: id, commentId, userId: userId });
      });
    },
    [application, mentionUsers, trackEvent, user.sub, workspace],
  );

  return (
    <div className="p-2">
      <ThreadHeader threadId={threadId} onClose={onClose} disableResolve={!hasComments} isResolved={isResolved} />
      {hasComments ? <CommentsList ref={commentsListHandleRef} comments={comments} /> : null}
      <div className="p-2 border rounded-md">
        <CommentTextArea
          id={threadId}
          autoFocus
          onSubmitComment={onSubmitComment}
          placeholder={isResolved ? f({ id: 'reply-to-re-open-discussion' }) : undefined}
        />
      </div>
    </div>
  );
};
