import { MessageBus } from '../../commands/framework/MessageBus';
import { ModelSuggestionGeneratedEvent } from '../../commands/ai/NotifyModelSuggestionReadyCommand';
import { EntityRepository } from '../../data/EntityRepository';
import { ConstructBase } from '../../entities/constructs/ConstructBase';

export class ModelSuggestionsProjection {
  constructor(
    private readonly messageBus: MessageBus,
    entityRepository: EntityRepository,
    owner: string,
  ) {
    this.messageBus.subscribe(
      [ModelSuggestionGeneratedEvent],
      () => {
        const suggestedScriptConstructs = entityRepository
          .list<ConstructBase>()
          .filter((e) => e.hasRequiredScript && e.attributes.suggested);
        // for each script use script.getAllEntities() to get child items for a script and filter for instance of ConstructBase
        //console.log('suggestions', suggestedScriptConstructs);
      },
      owner,
    );
  }
}
