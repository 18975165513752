import { useCallback } from 'react';
import { RenameEntityCommand, SetEntityAttributesCommand } from '@xspecs/single-source-model';
import { useApplication } from '../../../../wrappers/application-context/application-context';

export const useUpdateEntity = (id: string) => {
  const { application } = useApplication();

  const onRename = useCallback(
    (newName: string) => {
      application?.context?.messageBus.send(RenameEntityCommand, { entityId: id, newName });
    },
    [application?.context?.messageBus, id],
  );

  const onFontSizeChange = useCallback(
    (fontSize: number) => {
      application?.context.messageBus.send(SetEntityAttributesCommand, {
        entityId: id,
        attributeName: 'fontSize',
        attributeValue: fontSize,
      });
    },
    [application, id],
  );

  return {
    onRename,
    onFontSizeChange,
  } as const;
};
