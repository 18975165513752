import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, CardContent, ScrollArea, Textarea, toast, TreeView } from '@xspecs/design-system';
import { ArrowUp, Check, X } from 'lucide-react';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useMutation } from '@apollo/client';
import { CONTINUE_SUGGESTION_COMMAND } from '../../../graphql/mutations';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { useApplication } from '../../../wrappers/application-context/application-context';

type ChatProps = {
  modelId: string;
  modelType: string;
  onSend: (message: string) => void;
};

export const Chat = (props: ChatProps) => {
  const { onSend, modelType, modelId } = props;

  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();
  const { application } = useApplication();

  const setShowChatForModel = useSingleSourceStore.use.setShowChatForModel();
  const suggestionsTree = useSingleSourceStore.use.suggestionsTree();
  const setConstructToPanTo = useSingleSourceStore.use.setConstructToPanTo();
  const messageIdRef = useRef(1);

  const [messages, setMessages] = useState([
    {
      id: 9999,
      text: 'Hey! How can I help you today?',
      sender: 'bot',
    },
  ]);
  const [input, setInput] = useState('');
  const [selectedSuggestedItems, setSelectedSuggestedItems] = useState<string[]>([]);
  const [continueSuggestion, { loading: continueSuggestionLoading }] = useMutation(CONTINUE_SUGGESTION_COMMAND);

  const handleSend = () => {
    if (!input.trim()) return;
    setMessages((prev) => [...prev, { id: ++messageIdRef.current, text: input, sender: 'user' }]);
    setInput('');
    onSend(input);
  };

  const closeChat = useCallback(() => {
    setShowChatForModel(undefined);
  }, [setShowChatForModel]);

  const isSendDisabled = input.trim() === '';

  const onSelectFile = useCallback(
    (file: { id: string | number }) => {
      setConstructToPanTo(file.id.toString());
    },
    [setConstructToPanTo],
  );

  const onCheckTreeItem = useCallback((id: string, checked: boolean) => {
    setSelectedSuggestedItems((prev) => {
      if (checked) {
        return [...prev, id];
      }
      return prev.filter((item) => item !== id);
    });
  }, []);

  useEffect(() => {
    if (suggestionsTree.tree.length === 0) return;
    setMessages((prev) => {
      const botMessage = prev.find((msg) => msg.sender === 'bot')!;
      botMessage.text = 'tree';
      return [...prev];
    });
  }, [suggestionsTree.tree.length]);

  return (
    <Card className="size-full flex flex-col">
      <Button
        variant="ghost"
        size="icon"
        className="z-10 h-6 w-6 p-0 text-muted-foreground hover:text-foreground place-self-end m-2"
        onClick={closeChat}
      >
        <X className="h-4 w-4" />
      </Button>
      <CardContent className="flex-1 p-4 overflow-hidden">
        <ScrollArea className="h-full pr-4">
          <div className="flex flex-col gap-4">
            {messages.map((msg) => (
              <div
                key={msg.id}
                className={`max-w-[75%] px-4 py-2 rounded-2xl shadow-sm text-sm whitespace-pre-wrap ${
                  msg.sender === 'user'
                    ? 'self-end bg-base-primary text-button-white'
                    : 'self-start bg-muted text-muted-foreground'
                }`}
              >
                {msg.text === 'tree' && suggestionsTree.tree.length > 0 ? (
                  <div className="flex flex-col">
                    <TreeView
                      files={suggestionsTree.tree}
                      rootId={suggestionsTree.rootId}
                      onSelectFile={onSelectFile}
                      onCheckTreeItem={onCheckTreeItem}
                    />
                    {selectedSuggestedItems.length > 0 ? (
                      <Button
                        className="w-8 h-8 ml-auto"
                        size="icon"
                        variant="outline"
                        onClick={async () => {
                          const modelContext = application?.getModelContext();
                          if (!organization || !workspace || !modelContext) return;
                          const { data } = await continueSuggestion({
                            variables: {
                              args: {
                                clientId: modelContext.modelFile.clientId,
                                modelId: modelId,
                                modelType: modelType,
                                organizationId: organization.id,
                                workspaceId: workspace.id,
                                selections: selectedSuggestedItems
                                  .map((itemId) => {
                                    const suggestionItem = suggestionsTree.tree.find((entity) => entity.id === itemId)!;
                                    if (!suggestionItem) return null;
                                    return {
                                      id: suggestionItem.id,
                                      name: suggestionItem.text,
                                    };
                                  })
                                  .filter(Boolean) as any[],
                                userPrompt: messages.find((msg) => msg.sender === 'user')?.text || '',
                              },
                            },
                          });
                          if (!data?.continueModelSuggestion.error) {
                            toast.success('Suggestion accepted');
                          }
                        }}
                      >
                        <Check />
                      </Button>
                    ) : null}
                  </div>
                ) : msg.text !== 'tree' ? (
                  msg.text
                ) : (
                  'Hey! How can I help you today?'
                )}
              </div>
            ))}
          </div>
        </ScrollArea>
      </CardContent>

      <div className="p-4 border-t">
        <div className="flex flex-col items-end gap-2">
          <Textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Ask Auto... (type / for suggestions)"
            className="resize-none flex-1 focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0"
            onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && (e.preventDefault(), handleSend())}
          />
          <Button
            size="icon"
            className={`h-[18px] w-[18px] p-0 rounded-full transition ${
              isSendDisabled
                ? 'bg-muted text-muted-foreground cursor-not-allowed'
                : 'bg-primary text-primary-foreground hover:bg-primary/90'
            }`}
            variant="ghost"
            onClick={handleSend}
            disabled={isSendDisabled}
          >
            <ArrowUp className="w-3.5 h-3.5" />
          </Button>
        </div>
      </div>
    </Card>
  );
};
